import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../assets/environments/environment';
import {MyINavData, navItems} from '../../_nav';
import {AuthenticationService} from '../../core/services/authentication.service';
import {Subscription} from 'rxjs';
import {TranslatorService} from '../../core/services/translator.service';
import {CompanyStatusEnum} from '../../shared/enums/Company.status.enum';
import {TranslateService} from '@ngx-translate/core';
import {EmployeeService} from '../../views/employee/services/employee.service';
import {NGXLogger} from 'ngx-logger';
import {BadgesService, BadgeWithKey} from '../../shared/service/badges.service';
import {faLock, faUser, faWrench} from '@fortawesome/free-solid-svg-icons';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {CguModalComponent} from '../../shared/components/cgu-modal/cgu-modal.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit, AfterViewInit, OnDestroy {


  constructor(
    private authService: AuthenticationService,
    private employeeService: EmployeeService,
    private translateService: TranslateService,
    private badgesService: BadgesService,
    private logger: NGXLogger,
    private modalService: BsModalService
  ) {}
  protected readonly faUser = faUser;
  protected readonly faWrench = faWrench;
  protected readonly faLock = faLock;
  username: string;
  imageSrc: any;

  public sidebarMinimized = false;
  public navItems;
  private subscriptions: Subscription[] = [];
  modalRef?: BsModalRef;

  protected readonly environment = environment;

  ngOnInit(): void {
    this.loadNameAndProfilePicture();
    this.refreshSideMenuItems();
    this.setInvoiceGenerateBadges();
    this.setContractBadges();
    this.setPurchaseOrderBadges();

    this.translateService.onLangChange.subscribe(languageChanged => {
      this.refreshSideMenuItems();
    });
    this.subscriptions.push(
      this.badgesService.badgesChanged$.subscribe((badgeWithKey: BadgeWithKey) => {
        const item: MyINavData = navItems.find((obj) => obj.key === badgeWithKey.key);
        item.badge = badgeWithKey.badge;
        this.refreshSideMenuItems();
      })
    );
  }

  ngAfterViewInit() {
    if (this.authService.isAuthenticated()) {
      setTimeout(() => this.checkCgu(), 2000);
    }
  }

  setInvoiceGenerateBadges() {
    const item: MyINavData = this.navItems.find((obj) => obj.key === 'core.components.navbar.to-generate');
    if (item) {
      this.badgesService.loadInvoiceToGenerateBadge(item.key);
    }
  }

  setPurchaseOrderBadges() {
    const item: MyINavData = this.navItems.find((obj) => obj.key === 'core.components.navbar.purchase-orders');
    if (item) {
      this.badgesService.loadPurchaseOrderBadge(item.key);
    }
  }

  setContractBadges() {
    const item: MyINavData = this.navItems.find((obj) => obj.key === 'core.components.navbar.contracts');
    if (item) {
      this.badgesService.loadContractBadge(item.key);
    }
  }

  translateKeys(item): void {
    if ('key' in item) {
      const trans = this.translateService.instant(`${item.key}`);
      if (trans !== `${item.key}`) {
        item.name = trans;
      }
    }
    if (item.children && item.children.length > 0) {
      item.children.map((child: any) => this.translateKeys(child));
    }
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    this.authService.logout();
    localStorage.removeItem(TranslatorService.LANGUAGE);
  }

  private filterSidebarItems(navItems: MyINavData[]): MyINavData[] {
    const filteredNavItems: MyINavData[] = new Array<MyINavData>();
    navItems.forEach((item) => {
      if (
        item.role.find((role) => role === this.authService.getRole()) &&
        (item.companyStatus == null ||
          item.companyStatus.find(
            (status) => status === this.authService.getCompanyStatus()
          ))
      ) {
        this.translateKeys(item);
        filteredNavItems.push(item);
      }
    });
    return filteredNavItems;
  }

  isCompanyIncomplete() {
    return this.authService.getCompanyStatus() && CompanyStatusEnum.COMPLETED !== this.authService.getCompanyStatus();
  }

  isNotProvider() {
    return this.authService.getRole() !== 'ROLE_PROVIDER';
  }

  private refreshSideMenuItems() {
    this.navItems = this.filterSidebarItems(navItems);
    // It is a workaround related: https://github.com/coreui/coreui-free-angular-admin-template/issues/123
    this.navItems = JSON.parse(JSON.stringify(this.navItems));
  }

  private loadNameAndProfilePicture() {
    this.subscriptions.push(this.authService.getUsername().subscribe(value => {
      if (value) {
        this.username = value;
      }
    }));
    this.subscriptions.push(this.authService.getPicture().subscribe(value => {
      if (value) {
        this.imageSrc = value;
      } else {
        this.subscriptions.push(this.employeeService.getMyPicture().subscribe(
          (res: any) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
              const imageSrc = reader.result as string;
              this.authService.setPicture(imageSrc);
              }, false);
            if (res) {
              reader.readAsDataURL(res);
            } else {
              this.imageSrc = null;
            }
          }, error => {
            this.logger.error(error.url, '- STATUS :', error.status);
          }
        ));
      }
    }));
  }

  public showCGU(mandatory?: boolean) {
    if (mandatory) {
      this.modalRef = this.modalService.show(CguModalComponent, { backdrop: 'static', class: 'modal-lg', initialState: {cguToBeValidated: true} });
    } else {
      this.modalRef = this.modalService.show(CguModalComponent, { class: 'modal-lg', initialState: {cguToBeValidated: false}});
    }
  }

  checkCgu() {
    this.authService.getCguStatus().then(
      isValid => {
        if (!isValid) {
          this.showCGU(true);
        }
      }
    );
  }

  ngOnDestroy() {this.subscriptions.forEach(sub => sub.unsubscribe()); }
}
