import {Component} from '@angular/core';
import {MenuItemModel} from '../../../shared/models/menu-item.model';
import {ActivityReportService} from '../../activity-report/services/activity-report.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'to-generate',
  templateUrl: './to-generate.component.html',
  styleUrls: ['./to-generate.component.scss'],
})
export class ToGenerateComponent {
  menu: MenuItemModel[] = [];

  constructor(
    private activityReportService: ActivityReportService,
    private translateService: TranslateService,
  ) {
    this.constructMenu();
  }

  private constructMenu(): void {
    this.activityReportService.getPendingInvoicesCount().subscribe({
      next: response => {
        this.menu = [];
        this.menu.push(new MenuItemModel(this.translateService.instant('Mission_view.billing.activity_sheets'), 'activity-sheets', response.activitySheet));
        this.menu.push(new MenuItemModel(this.translateService.instant('Mission_view.billing.due_date'), 'due-payments', response.duePayment));
        this.menu.push(new MenuItemModel(this.translateService.instant('Mission_view.billing.on_call'), 'on-calls', response.onCall));
        this.menu.push(new MenuItemModel(this.translateService.instant('Mission_view.billing.fees'), 'fees', response.fee));
      }
    });
  }
}
