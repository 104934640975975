import {TaxGetResource} from './TaxGroupModel';
import {CountryEnum} from '../../enums/Country.enum';

export class CompanyTaxesPostModel {
  taxGroupId: string;
  numbers: TaxNumber[];
}

export class TaxNumber {
  name?: string;
  taxTypeId: string;
  number: string;
}

export class TaxGroupGetModel {
  id: string;
  country: CountryEnum;
  name: String ;
  taxes: Set<TaxGetResource>;
}

export class CompanyTaxesGetModel {
  configId: string;
  taxGroup: TaxGroupGetModel;
  numbers: TaxNumber[];
}
