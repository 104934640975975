import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ActivitySheetModel, StatusEnum} from '../models/activity-report.model';
import {commonProperties} from '../../../../assets/environments/environment.common';
import {environment} from '../../../../assets/environments/environment';

import { ListContentModel } from '../../../shared/models/list-content.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityReportService {

  constructor(private httpClient: HttpClient) {}

  getActivitySheetsByPage(page: number, size: number, sort: string, search: string, status: StatusEnum[]) {
    let params = new HttpParams();

    if (search !== '' && search !== null) {
      params = params.set('search', search);
    }
    // @ts-ignore force status assignament in params
    params = params.set('status', status);
    params = params.set('page', page.toString()).set('size', size.toString()).set('sort', sort).set('summary', 'true');
    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.activitySheet, {params});
  }

  getActivitySheetAccountingMonthSummary(page, size): Observable<any> {
    let params = new HttpParams();

    params = params.set('size', size.toString()).set('page', page.toString());

    return this.httpClient.get(environment.api_root + commonProperties.activitySheetAccountingMonthSummary, {params});
  }

  getActivitySheetHistory(page: number, size: number, sort: string, summary: boolean, status: StatusEnum[]): Observable<any> {
    let params = new HttpParams();

    params = params.set('size', size.toString()).set('page', page.toString()).set('sort', sort).set('summary', summary.toString());
    // @ts-ignore
    params = params.set('status', status);

    return this.httpClient.get(environment.api_root + commonProperties.activitySheet, {params});
  }

  getActivitySheetOfMonth(sort: string, summary: boolean, accountingMonth: string): Observable<any> {
    let params = new HttpParams();

    params = params.set('sort', sort).set('summary', summary.toString());
    // @ts-ignore
    params = params.set('status', status);
    params = params.set('accountingMonth', accountingMonth);

    return this.httpClient.get(environment.api_root + commonProperties.activitySheet, {params});
  }

  getActivitySheetsWaitingForInvoiceGeneration(page: number, size: any, sort: any, search: any) {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }
    params = params.set('summary', 'true');
    params = params.set('status', 'VALIDATED');
    params = params.set('invoiceGenerated', 'false');
    params = params.set('invoicable', 'true');
    params = params.set('size', size.toString());
    params = params.set('page', page.toString());
    params = params.set('sort', sort);

    return this.httpClient.get(environment.api_root + commonProperties.activitySheet, {params});
  }

  getActivitySheetById(id: any): Observable<any> {
    return this.httpClient.get(environment.api_root + commonProperties.activitySheetById.replace(':id', id));
  }

  patchActivitySheetById(id: any, status: StatusEnum, comment: string, formValue: any): Observable<any> {

    const fileContent = formValue.attachmentFile;
    const fileName =  formValue.attachmentFileName;

    return this.httpClient.patch(environment.api_root + commonProperties.activitySheetById.replace(':id', id), {
      status: status,
      comment: comment,
      fileContent: {fileContent, fileName}

    });
  }

  postActivitySheetPostEntry(id: any, date: string, quantity: number): Observable<any> {
    return this.httpClient.post(environment.api_root + commonProperties.activitySheetPostEntry.replace(':id', id), {
      date: date,
      quantity: quantity
    });
  }

  postActivitySheetPostEntries(id: any, array): Observable<any> {
    return this.httpClient.post(environment.api_root + commonProperties.activitySheetPostEntries.replace(':id', id), {entries: array});
  }

  patchActivitySheetEntry(id: any, quantity: number, entryId: any): Observable<any> {
    return this.httpClient.patch(environment.api_root + commonProperties.activitySheetEntry.replace(':id', id).replace(':entryId', entryId), {quantity: quantity});
  }

  deleteActivitySheetEntry(id: any, entryId: any): Observable<any> {
    return this.httpClient.delete(environment.api_root + commonProperties.activitySheetEntry.replace(':id', id).replace(':entryId', entryId));
  }

  deleteActivitySheetEntries(id: any): Observable<any> {
    return this.httpClient.delete(environment.api_root + commonProperties.activitySheetEntries.replace(':id', id));
  }

  activitySheetGetFile(id: any) {
    const headers = new HttpHeaders().set('Accept', 'application/pdf');
    const URL = environment.api_root + commonProperties.activitySheetGetFile.replace(':id', id);
    return this.httpClient.get<Blob>(URL, {
      observe: 'response',
      headers: headers,
      responseType: 'blob' as 'json'
    });
  }

  activitySheetUploadFile(id: any, formValue: any): Observable<any> {
    return this.httpClient.post(environment.api_root + commonProperties.activitySheetUploadFile.replace(':id', id),
      {
        fileContent: formValue.attachmentFile,
        fileName: formValue.attachmentFileName
      });
  }

  activitySheetDeleteFile(id: any): Observable<any> {
    return this.httpClient.delete(environment.api_root + commonProperties.activitySheetDeleteFile.replace(':id', id));
  }

  getPendingInvoicesCount(): Observable<any> {
    return this.httpClient.get(environment.api_root + commonProperties.activitySheetPendingInvoices);
  }

  cancelActivitySheet(activitySheet: ActivitySheetModel, comment: string, newStatus: string) {
    return this.httpClient.patch(environment.api_root + commonProperties.activitySheetById.replace(':id', activitySheet.id.toString()),
      {
        status: newStatus,
        refusalComment: comment
      });
  }

}
