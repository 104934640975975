export enum CreditNoteErrorsEnum {
  QUANTITY_NOT_VALID= 'QUANTITY_NOT_VALID',
  UNIT_PRICE_NOT_VALID= 'UNIT_PRICE_NOT_VALID',
  INVALID_DATE= 'INVALID_DATE',
  INVALID_AMOUNT= 'INVALID_AMOUNT',
  INVOICE_DATE_INVALID= 'INVOICE_DATE_INVALID',
  INVOICE_STATUS_INVALID= 'INVOICE_STATUS_INVALID',
  CREDIT_NOTE_ALREADY_EXIST= 'CREDIT_NOTE_ALREADY_EXIST',
  INVOICE_NUMBER_INVALID= 'INVOICE_NUMBER_INVALID'
}
