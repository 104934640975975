import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {UploadModalComponent} from '../../../../shared/components/upload-modal/upload-modal.component';
import {regex} from '../../../../shared/regex/form.regex';
import {BadgesService} from '../../../../shared/service/badges.service';
import {FileUtilsService} from '../../../../shared/utils/file-utils.service';
import {maximumSizeFile} from '../../../../shared/validators/maximum-size-file.validator';
import {requiredFileType} from '../../../../shared/validators/required-file-type.validator';
import {ActivityReportService} from '../../../activity-report/services/activity-report.service';
import {FeesService} from '../../../fees/services/fees.service';
import {OnCallService} from '../../../on-call/services/on-call.service';
import {ActivityTypeEnum} from '../../models/activity-type.enum';
import {ActivityModel, DuePaymentTypeEnum} from '../../models/activity.model';
import {
  InvoiceProformaBodyResourceModel,
  SupplierInvoiceConfig,
} from '../../models/invoice-proforma-body-resource.model';
import {InvoiceService} from '../../services/invoice.service';
import {ProformaErrorsEnum} from '../../models/proforma-errors.enum';
import {
  ButtonActionEnum,
  ButtonTypeEnum,
  DateFormatterService,
  IspColoursEnum
} from '@i-supplier/angular-shared-module';

@Component({
  selector: 'proforma-generation',
  templateUrl: './proforma-generation.component.html',
  styleUrls: ['./proforma-generation.component.scss']
})
export class ProformaGenerationComponent implements OnDestroy, OnInit {

  constructor(
    private invoiceService: InvoiceService,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private activityReportService: ActivityReportService,
    private feeService: FeesService,
    private onCallService: OnCallService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public uploadModal: MatDialog,
    private badgeService: BadgesService,
    private dateFormatterService: DateFormatterService
  ) {
    this.currentDate = new Date();
  }
  protected readonly faTimes = faTimes;
  proformaData: InvoiceProformaBodyResourceModel;
  activity: ActivityModel;
  invoiceProformaGenerationForm: FormGroup;
  invoiceTransmitForm: FormGroup;
  manualInvoiceFilename;
  manualInvoiceFileToUpload: File = null;
  advancePaymentInDaysValueChanged: boolean = false;
  advancePaymentDiscount: string = '--';
  subscriptions: Subscription[] = [];
  supplierInvoiceConfig: SupplierInvoiceConfig;
  loaders: { [key: string]: boolean } = {};
  pdf_source: {[key: string]: any} = {};
  public currentDate: Date;
  public accountingMonth: Date;

  protected readonly IspColoursEnum = IspColoursEnum;
  protected readonly ButtonActionEnum = ButtonActionEnum;
  protected readonly ButtonTypeEnum = ButtonTypeEnum;

  getSupplierInvoiceConfig() {
    this.invoiceService.getSupplierInvoiceConfig(this.activity)
      .subscribe((res: any) => {
        this.supplierInvoiceConfig = res;
        if (this.supplierInvoiceConfig) {
          this.constructInvoiceProformaGenerationForm();
        }
      });
  }

  ngOnInit(): void {
    let ids = null;
    switch (this.activatedRoute.snapshot.params['type']) {
      case 'due-payment':
        this.invoiceService.getDuePaymentsById(this.activatedRoute.snapshot.params['id']).subscribe(value => {
          this.activity = this.invoiceService.mapDuePaymentToActivity(value);
          this.accountingMonth = new Date(this.activity.accountingMonth);
          this.getSupplierInvoiceConfig();
        });
        break;
      case 'activity-sheet':
        this.activityReportService.getActivitySheetById(this.activatedRoute.snapshot.params['id']).subscribe(value => {
          this.activity = this.invoiceService.mapActivitySheetToActivity(value);
          this.accountingMonth = new Date(this.activity.accountingMonth);
          this.getSupplierInvoiceConfig();
        });
        break;
      case 'on-call':
        ids = this.activatedRoute.snapshot.params['id'].split(',');
        this.onCallService.getOnCallById(parseInt(ids[0], 10)).subscribe(value => {
          this.activity = this.invoiceService.mapOnCallToActivity(value);
          this.activity.onCallIds = ids;
          this.accountingMonth = new Date(this.activity.accountingMonth);
          this.getSupplierInvoiceConfig();
        });
        break;
      case 'fee':
        ids = this.activatedRoute.snapshot.params['id'].split(',');
        this.feeService.getFeeById(parseInt(ids[0], 10)).subscribe(value => {
          this.activity = this.invoiceService.mapFeeToActivity(value);
          this.activity.feeIds = ids;
          this.accountingMonth = new Date(this.activity.accountingMonth);
          this.getSupplierInvoiceConfig();
        });
        break;
      default:
        this.router.navigate(['/accounting']);
    }
  }

  canDisplay(formGroup: FormGroup, formControl: string): boolean {
    if (formGroup.get(formControl).disabled) {
      return false;
    } else if (formGroup.get(formControl).pristine) {
      return false;
    } else {
      return formGroup.get(formControl).value !== '';
    }
  }

  clearInputField(formGroup: FormGroup, formControl: string): void {
    formGroup.get(formControl).reset();
  }

  invoiceProformaGenerationFormSubmit() {
    this.loaders['invoiceProformaGenerationFormSubmit'] = true;

    const proformaBodyRequest = this.invoiceService.prepareProformaBody(
      this.invoiceProformaGenerationForm.value,
      this.activity,
      this.advancePaymentInDaysValueChanged
    );

    this.invoiceService.generateProforma(proformaBodyRequest).subscribe({
      next: (res: any) => {
        this.invoiceProformaGenerationForm.disable();
        this.pdf_source['proformaFile'] = res.fileContent;
        this.constructInvoiceTransmitForm(proformaBodyRequest);
        this.loaders['invoiceProformaGenerationFormSubmit'] = false;
      },
      error: (error: any) => {
        this.loaders['invoiceProformaGenerationFormSubmit'] = false;
        if (error.status === 409 || error.error?.errors?.invoiceNumber[0].code === 'UniqueInvoice') {
          this.toastrService.error(this.translateService.instant('proforma_generation.proforma-generation-ts.invoice-generation-form-submit.UniqueInvoice'));
        } else {
          this.toastrService.error(this.translateService.instant('global.ts.error'));
        }
      },
    });
  }

  cancelProforma() {
    this.invoiceProformaGenerationForm.enable();
    this.invoiceProformaGenerationForm.reset();
    this.invoiceProformaGenerationForm.get('advancePaymentInDays').patchValue(this.supplierInvoiceConfig.billableUnder);
    this.advancePaymentInDaysValueChanged = false;
    this.manualInvoiceFileToUpload = null;
    this.pdf_source['proformaFile'] = null;
    this.loaders['invoiceProformaGenerationFormSubmit'] = false;
  }

  invoiceTransmitFormSubmit() {
    this.loaders['invoiceTransmitFormSubmit'] = true;
    const transmitData = Object.assign(new InvoiceProformaBodyResourceModel(), this.proformaData);
    transmitData.proforma = false;

    if (this.invoiceTransmitForm.enabled) {
      this.invoiceService.mergeValues(transmitData, this.invoiceTransmitForm.value);
      transmitData.invoiceDate = this.dateFormatterService.timeZoneOffset(transmitData.invoiceDate);
    }

    this.invoiceService.generateProforma(transmitData).subscribe({
      next: () => {
        this.loaders['invoiceTransmitFormSubmit'] = false;
        this.loaders['invoiceProformaGenerationFormSubmit'] = false;
        this.toastrService.success(this.translateService.instant(`Invoice.ts.${transmitData.fileContent ? 'transmit_manual' : 'transmit_auto'}`));
        this.badgeService.refreshInvoiceToGenerateBadge();
        this.router.navigate(['/accounting/invoices']);
        },
      error: (err: any) => {
        this.loaders['invoiceTransmitFormSubmit'] = false;
        this.loaders['invoiceProformaGenerationFormSubmit'] = false;
        this.handleValidationError(err.error);
        },
    });
  }

  clearFile() {
    this.manualInvoiceFilename = null;
    this.manualInvoiceFileToUpload = null;
    this.invoiceTransmitForm.reset();
    this.pdf_source['proformaFile'] = this.pdf_source['temporary'];
    this.pdf_source['temporary'] = null;
    this.disableManualInvoice();
  }

  async onFileChange(file: File) {
    this.manualInvoiceFilename = file.name;
    this.manualInvoiceFileToUpload = file;
    this.enableManualInvoice();
    this.invoiceTransmitForm.get('fileSize').setValue(this.manualInvoiceFileToUpload);
    const fileBase64 = await FileUtilsService.convertFileToBase64(this.manualInvoiceFileToUpload);
    this.invoiceTransmitForm.get('fileName').setValue(this.manualInvoiceFileToUpload.name);
    this.invoiceTransmitForm.get('fileContent').setValue(fileBase64);
  }

  enableManualInvoice() {
    this.invoiceTransmitForm.enable();
  }

  disableManualInvoice() {
    this.invoiceTransmitForm.disable();
  }

  isDuePaymentByDate() {
    return (this.activity.type === ActivityTypeEnum.DUE_PAYMENT && this.activity.duePaymentType === DuePaymentTypeEnum.DATE);
  }

  isDuePaymentByEvent() {
    return (this.activity.type === ActivityTypeEnum.DUE_PAYMENT && this.activity.duePaymentType === DuePaymentTypeEnum.EVENT);
  }

  isDuePayment() {
    return (this.activity.type === ActivityTypeEnum.DUE_PAYMENT);
  }

  isActivitySheet() {
    return this.activity.type === ActivityTypeEnum.ACTIVITY_SHEET;
  }

  /**
   * Build reactive form with rules on advancePayment changes
   * @private
   */
  private constructInvoiceProformaGenerationForm() {
    this.invoiceProformaGenerationForm = this.fb.group({
      invoiceNumber: [null, [Validators.pattern(regex.noLeadingTrailingSpacesPattern), Validators.required]],
      advancePaymentInDays: [this.supplierInvoiceConfig.billableUnder, [Validators.min(3)]]
    });

    const advancePaymentInDaysControl = this.invoiceProformaGenerationForm.get('advancePaymentInDays');
    this.subscriptions.push(advancePaymentInDaysControl.valueChanges.subscribe(advancePaymentDays => {
      if (advancePaymentDays) {
        if ([this.supplierInvoiceConfig.billableUnder.toString()].includes(advancePaymentDays)) {
          this.resetAdvancePayment();
          return;
        } else if (advancePaymentDays !== this.supplierInvoiceConfig.billableUnder) {
          this.advancePaymentInDaysValueChanged = true;
          advancePaymentInDaysControl.setValidators([
            Validators.required,
            Validators.pattern(regex.numberOnlyPattern),
            Validators.min(3),
            Validators.max(this.supplierInvoiceConfig.billableUnder)
          ]);
          advancePaymentInDaysControl.updateValueAndValidity({emitEvent: false}); // Unsubscribe before update
          advancePaymentInDaysControl.markAsTouched();

          if (this.invoiceProformaGenerationForm.get('advancePaymentInDays').valid) {
            this.getDiscount(advancePaymentDays);
          }
        }
      } else {
        this.advancePaymentDiscount = '--';
      }
    }));
  }

  resetAdvancePayment() {
    this.advancePaymentInDaysValueChanged = false;
    this.clearInputField(this.invoiceProformaGenerationForm, 'advancePaymentInDays');
    this.invoiceProformaGenerationForm.get('advancePaymentInDays').clearValidators();
    this.invoiceProformaGenerationForm.get('advancePaymentInDays').patchValue(this.supplierInvoiceConfig.billableUnder);
  }

  constructInvoiceTransmitForm(proformaData: InvoiceProformaBodyResourceModel) {
    this.proformaData = proformaData;
    this.invoiceTransmitForm = this.fb.group({
      fileSize: [null, [maximumSizeFile(2, 'mo')]],
      fileContent: [null, Validators.required],
      fileName: [null, [requiredFileType('pdf')]],
      invoiceDate: [null, [Validators.required]],
      taxAmount: [null, [Validators.required]],
      taxlessTotal: [null, [Validators.required]],
      totalIncludingTax: [null, [Validators.required]]
    });
    this.invoiceTransmitForm.disable();
  }

  openModal(): void {
    const modalRef = this.uploadModal.open(UploadModalComponent, {
      width: '658px',
      data: {},
    });

    modalRef.afterClosed().subscribe((response) => {
      if (response) {
        this.onFileChange(response.file);
        this.pdf_source['temporary'] = this.pdf_source['proformaFile'];
        this.pdf_source['proformaFile'] = response.preview;
      }
    });
  }

  getDiscount(advancePayment: any) {
    this.invoiceService.getAdvancePaymentDiscount(advancePayment).subscribe({
      next: data => this.advancePaymentDiscount = data.discountPercentage,
      error: () => {
        this.advancePaymentDiscount = '--';
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      }});
  }

  private handleValidationError(error: any) {
    if (!error.errors.supplierInvoicePostResource) {
      this.toastrService.error(this.translateService.instant('Invoice.ts.transmit_failure'));
      return;
    }

    const errorsArray = error.errors.supplierInvoicePostResource;
    const proformaErrors = Object.keys(ProformaErrorsEnum);

    errorsArray.forEach((element: { message: string }) => this.toastrService.error(this.translateService.instant(proformaErrors.includes(element.message) ? `Invoice.error-messages.proforma.${element.message}` : 'global.ts.error')));
  }

  /**
   * Clear the activity for which the generation was started and unsubscribe from all the subscriptions made
   */
  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }
}
