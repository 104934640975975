<div class="animated fadeIn">
  <form [formGroup]="callForTenderOfferCreationForm" (ngSubmit)="openSubmitConfirmModal()"
        (keydown.enter)="$event.preventDefault()">
    <div class="row" *ngIf="!isInUpdateMode">
      <div class="col-md-12">
        <h2
          class="underline">{{'Call_for_tender_offer_creation.cftoc_title_creation'|translate}}{{selectedCallForTender?.title}}</h2>
      </div>
    </div>

    <div class="row" *ngIf="isInUpdateMode">
      <div class="col-md-12">
        <h2
          class="underline">{{'Call_for_tender_offer_creation.cftoc_title_update'|translate}}{{selectedCallForTender?.title}}</h2>
      </div>
    </div>

    <alert type="danger" class="slide-in-animation" *ngIf="offerAlreadyCreated">
      <strong>{{'Call_for_tender_offer_creation.notice'|translate}}</strong>{{'Call_for_tender_offer_creation.msg_danger'|translate}}
    </alert>

    <div class="row">
      <div class="col-md-6">
        <h3 class="mb-3">{{'Call_for_tender_offer_creation.provider'|translate}}</h3>
        <ng-container *ngIf="providerSearchMode">
          <!--  Default display -->
          <input type="text" class="form-control form-control-lg" #providerSearchInput
                 (input)="searchProvider(providerSearchInput.value)"
                 [value]="getProviderSearchValue()"
                 placeholder="{{'Call_for_tender_offer_creation.search_your_provider_by_name'|translate}}">

          <div *ngIf="!isProviderSearchValueActive()" @providerSectionDisplay>
            <p class="text-center font-weight-bold mt-4">
              <button class="btn btn-primary mr-1"
                      (click)="providerSearchInput.focus();$event.stopPropagation();false">
                {{'Call_for_tender_offer_creation.search_your_provider'|translate}}
              </button>
              {{'Call_for_tender_offer_creation.or'|translate}}
              <button class="btn btn-primary ml-1"
                      (click)="displayCreateProviderCard()">{{'Call_for_tender_offer_creation.fill_the_information'|translate}}
              </button>

            </p>
          </div>

          <ng-container *ngIf="isProviderSearchValueActive()">

            <!-- Search : no results-->
            <div *ngIf="providerSearchList.length === 0" @providerSectionDisplay>
              <div class="row my-4">
                <div class="col text-center">
                  <img src="assets/img/result_empty.svg" alt="Result empty image"
                       style="width: 25rem;">
                </div>
              </div>
              <p
                class="text-center font-weight-bold">{{'Call_for_tender_offer_creation.no_result_provider'|translate}}
                <span class="btn-link font-weight-normal"
                      (click)="displayCreateProviderCard()">{{'Call_for_tender_offer_creation.fill_the_information_manually'|translate}}</span>
              </p>
            </div>

            <!--    Search : results > 0-->
            <ng-container *ngIf="providerSearchList.length !== 0">
              <div class="row mt-2">
                <div class="col-4">
                  <p
                    class="font-weight-bold">{{providerSearchTotalResult}} {{'Call_for_tender_offer_creation.result'|translate}}</p>
                </div>
                <div class="col-8">
                  <p
                    class="font-weight-bold float-right">{{'Call_for_tender_offer_creation.no_provider'|translate}}
                    <span class="btn-link font-weight-normal"
                          (click)="displayCreateProviderCard()">
                      {{'Call_for_tender_offer_creation.fill_the_information_manually'|translate}}
                    </span>
                  </p>
                </div>
              </div>
              <div class="row mt-0" [@providerSearchDisplay]="page">
                <ng-container *ngFor="let provider of providerSearchList">
                  <div class="col">
                    <div class="card box-shadow-hover cursor-pointer"
                         (click)="selectProvider(provider)">
                      <div class="card-body d-flex align-items-center">
                        <div class="provider-logo">
                          <ngx-avatars
                            [name]="provider?.firstName+' '+provider?.lastName"
                            bgColor="#F7F7F9"
                            fgColor="#8B5D5D"
                            size="80" textSizeRatio="2" initialsSize="2"
                          ></ngx-avatars>
                        </div>
                        <div class="provider-info ml-3">
                          <p class="font-weight-bold mb-1 ">{{provider.firstName | titlecase}}&nbsp;{{provider.lastName | uppercase}}</p>
                          <p class="text-muted ellipsis">{{provider.email}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="row" *ngIf="providerSearchTotalResult > 4">
                <div class="col">
                  <pagination class="float-right" [totalItems]="providerSearchTotalResult"
                              [maxSize]="5" [itemsPerPage]="4"
                              (pageChanged)="onPageChanged($event)"
                              [(ngModel)]="page"></pagination>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="isProviderSelected && userRole != 'ROLE_FREELANCER'">
          <div class="card box-shadow-hover slide-in-animation" @providerSectionDisplay>
            <div class="card-body d-flex align-items-center">
              <div class="provider-logo">
                <ngx-avatars [name]="providerSelected?.firstName+' '+providerSelected?.lastName"
                            bgColor="#F7F7F9"
                            fgColor="#8B5D5D"
                            size="80" textSizeRatio="2" initialsSize="2">
                </ngx-avatars>
              </div>
              <div class="supplier-info ml-3">
                <p
                  class="font-weight-bold mb-1 ">{{providerSelected.firstName | titlecase}}&nbsp;{{providerSelected.lastName | uppercase}}
                </p>
              </div>
            </div>
            <button type="button" class="btn btn-link ml-1 d-flex justify-content-start"
                    (click)="unselectProvider()">
              {{'Call_for_tender_offer_creation.unselect_provider'|translate}}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="userRole == 'ROLE_FREELANCER'">
          <div class="card box-shadow-hover slide-in-animation" @providerSectionDisplay>
            <div class="card-body d-flex align-items-center">
              <div class="provider-logo">
                <ngx-avatars [name]="userName" bgColor="#F7F7F9"
                            fgColor="#8B5D5D"
                            size="80" textSizeRatio="2" initialsSize="2">
                </ngx-avatars>
              </div>
              <div class="supplier-info ml-3">
                <p
                  class="font-weight-bold mb-1 ">{{userName}}{{' (' + nickName + ')'| uppercase}}
                </p>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="createProviderMode">
          <form [formGroup]="callForTenderOfferCreationForm" (ngSubmit)="submitProviderForm()">
            <div class="card box-shadow-hover slide-in-animation" @providerSectionDisplay>
              <div class="card-body">
                <div formGroupName="pointOfContact">
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label
                          for="first-name">{{'Call_for_tender_offer_creation.first_name'|translate}}
                          <span
                            class="ml-1 text-primary">*</span></label>
                        <input required type="text" class="form-control" id="first-name"
                               formControlName="firstName">
                        <app-form-feedback
                          *ngIf="callForTenderOfferCreationForm.get('pointOfContact.firstName').errors?.required && (callForTenderOfferCreationForm.get('pointOfContact.firstName').dirty || callForTenderOfferCreationForm.get('pointOfContact.firstName').touched)"
                          message="{{'Call_for_tender_offer_creation.input_form_control.field_required'|translate}}">
                        </app-form-feedback>
                        <app-form-feedback
                          *ngIf="callForTenderOfferCreationForm.get('pointOfContact.firstName').errors?.pattern && (callForTenderOfferCreationForm.get('pointOfContact.firstName').dirty || callForTenderOfferCreationForm.get('pointOfContact.firstName').touched)"
                          message="{{'Call_for_tender_offer_creation.input_form_control.field_content'|translate}}">
                        </app-form-feedback>
                        <app-form-feedback
                          *ngIf="callForTenderOfferCreationForm.get('pointOfContact.firstName').errors?.maxlength && (callForTenderOfferCreationForm.get('pointOfContact.firstName').dirty || callForTenderOfferCreationForm.get('pointOfContact.firstName').touched)"
                          message="{{'Call_for_tender_offer_creation.input_form_control.field_maximum_50'|translate}}">
                        </app-form-feedback>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label
                          for="last-name">{{'Call_for_tender_offer_creation.last_name'|translate}}
                          <span
                            class="ml-1 text-primary">*</span></label>
                        <input type="text" class="form-control" id="last-name"
                               formControlName="lastName">
                        <app-form-feedback
                          *ngIf="callForTenderOfferCreationForm.get('pointOfContact.lastName').errors?.required && (callForTenderOfferCreationForm.get('pointOfContact.lastName').dirty || callForTenderOfferCreationForm.get('pointOfContact.lastName').touched)"
                          message="{{'Call_for_tender_offer_creation.input_form_control.field_required'|translate}}">
                        </app-form-feedback>
                        <app-form-feedback
                          *ngIf="callForTenderOfferCreationForm.get('pointOfContact.lastName').errors?.pattern && (callForTenderOfferCreationForm.get('pointOfContact.lastName').dirty || callForTenderOfferCreationForm.get('pointOfContact.lastName').touched)"
                          message="{{'Call_for_tender_offer_creation.input_form_control.field_content'|translate}}">
                        </app-form-feedback>
                        <app-form-feedback
                          *ngIf="callForTenderOfferCreationForm.get('pointOfContact.lastName').errors?.maxlength && (callForTenderOfferCreationForm.get('pointOfContact.lastName').dirty || callForTenderOfferCreationForm.get('pointOfContact.lastName').touched)"
                          message="{{'Call_for_tender_offer_creation.input_form_control.field_maximum_50'|translate}}">
                        </app-form-feedback>
                        <app-form-feedback
                          *ngIf="callForTenderOfferCreationForm.get('pointOfContact.lastName').errors?.minlength && (callForTenderOfferCreationForm.get('pointOfContact.lastName').dirty || callForTenderOfferCreationForm.get('pointOfContact.lastName').touched)"
                          message="{{'Call_for_tender_offer_creation.input_form_control.field_minimum_2'|translate}}">
                        </app-form-feedback>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label
                          for="last-name">{{'Call_for_tender_offer_creation.email'|translate}}
                          <span
                            class="ml-1 text-primary">*</span></label>
                        <input type="text" class="form-control" id="email"
                               formControlName="email">
                        <app-form-feedback
                          *ngIf="callForTenderOfferCreationForm.get('pointOfContact.email').errors?.required && (callForTenderOfferCreationForm.get('pointOfContact.email').dirty || callForTenderOfferCreationForm.get('pointOfContact.email').touched)"
                          message="{{'Call_for_tender_offer_creation.input_form_control.field_required'|translate}}">
                        </app-form-feedback>
                        <app-form-feedback
                          *ngIf="callForTenderOfferCreationForm.get('pointOfContact.email').errors?.pattern && (callForTenderOfferCreationForm.get('pointOfContact.email').dirty || callForTenderOfferCreationForm.get('pointOfContact.email').touched)"
                          message="{{'global.feedback.pattern-email'|translate}}">
                        </app-form-feedback>
                        <app-form-feedback
                          *ngIf="callForTenderOfferCreationForm.get('pointOfContact.email').errors?.maxlength && (callForTenderOfferCreationForm.get('pointOfContact.email').dirty || callForTenderOfferCreationForm.get('pointOfContact.email').touched)"
                          message="{{'Call_for_tender_offer_creation.input_form_control.field_maximum_100'|translate}}">
                        </app-form-feedback>
                      </div>
                    </div>
                  </div>
                </div>
                <button class="btn btn-sm btn-primary float-right" type="submit"
                        [disabled]="!callForTenderOfferCreationForm.get('pointOfContact').valid"
                        @fadeIn>{{'Call_for_tender_offer_creation.save_changes_btn'|translate}}
                </button>
                <button type="button" class="btn btn-link pl-1" (click)="enableProviderSearch()"
                        *ngIf="createProviderMode">
                  {{'Call_for_tender_offer_creation.go_back_to_search'|translate}}
                </button>
              </div>
            </div>
          </form>
        </ng-container>
        <h3 class="mb-3">{{'Call_for_tender_offer_creation.profile'|translate}}</h3>
        <div class="card box-shadow-hover slide-in-animation">
          <div class="card-body">
            <div class="row">
              <div class="col-md-10 form-group">
                <label for="title">{{'Call_for_tender_offer_creation.title'|translate}}<span
                  class="text-primary ml-1">*</span></label>
                <textarea id="title" #title class="form-control" formControlName="title" type="text" rows="1"></textarea>
                <div class="float-right max-hint">{{title.value.length}} / 150</div>

                <app-form-feedback *ngIf="callForTenderOfferCreationForm.get('title').errors?.required &&
                                        (callForTenderOfferCreationForm.get('title').dirty || callForTenderOfferCreationForm.get('title').touched)"
                                   message="{{'Call_for_tender_offer_creation.input_form_control.field_required'|translate}}">
                </app-form-feedback>
                <app-form-feedback
                  *ngIf="callForTenderOfferCreationForm.get('title').errors?.pattern && (callForTenderOfferCreationForm.get('title').dirty || callForTenderOfferCreationForm.get('title').touched)"
                  message="This field may contain only letters, whitespaces and this special character: -">
                </app-form-feedback>
                <app-form-feedback
                  *ngIf="callForTenderOfferCreationForm.get('title').errors?.maxlength && (callForTenderOfferCreationForm.get('title').dirty || callForTenderOfferCreationForm.get('title').touched)"
                  message="{{'Call_for_tender_offer_creation.input_form_control.field_maximum_150'|translate}}">
                </app-form-feedback>
              </div>
            </div>

            <div class="row" *ngIf="selectedCallForTender?.contractMode">
              <div class="col-md-6 form-group">
                <label for="{{getDailyRateAmountControlName()}}">
                  {{ (selectedCallForTender?.contractMode === 'FIXED_PRICE' ? 'Call_for_tender_offer_creation.amount' : 'Call_for_tender_offer_creation.adr') | translate }}
                  <span class="ml-1 text-primary">*</span>
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="{{getDailyRateAmountControlName()}}"
                    [formControlName]="getDailyRateAmountControlName()"
                  >
                  <div class="input-group-append" *ngIf="callForTenderOfferCreationForm.get('currency').value">
                    <span class="input-group-text to-neg-curr">{{ callForTenderOfferCreationForm.get('currency').value | currencyFormat: 'symbol' }}</span>
                  </div>
                </div>
                <span class="float-right max-hint">
                  <ng-container
                    *ngIf="selectedCallForTender.contractMode === 'FIXED_PRICE' ?
                      (selectedCallForTender.minAmount !== 0 && selectedCallForTender.minAmount !== undefined) :
                      (selectedCallForTender.minDailyRate !== 0 && selectedCallForTender.minDailyRate !== undefined)"
                  >
                    {{ selectedCallForTender.contractMode === 'FIXED_PRICE' ? selectedCallForTender.minAmount : selectedCallForTender.minDailyRate }}
                    {{ selectedCallForTender.currency | currencyFormat: 'symbol' }}
                    -
                  </ng-container>
                  {{ selectedCallForTender.contractMode === 'FIXED_PRICE' ? selectedCallForTender.maxAmount : selectedCallForTender.maxDailyRate }}
                  {{ selectedCallForTender.currency | currencyFormat: 'symbol' }}
                  <span *ngIf="selectedCallForTender.dailyRateByDate;else not_day">{{ 'marketplace.by-day'|translate }}</span>
                </span>
                <app-form-feedback
                  *ngIf="callForTenderOfferCreationForm.get(getDailyRateAmountControlName()).errors?.required &&
                        (callForTenderOfferCreationForm.get(getDailyRateAmountControlName()).dirty ||
                        callForTenderOfferCreationForm.get(getDailyRateAmountControlName()).touched)"
                  message="{{ 'Call_for_tender_offer_creation.input_form_control.field_required' | translate }}"
                ></app-form-feedback>
                <app-form-feedback
                  *ngIf="callForTenderOfferCreationForm.get(getDailyRateAmountControlName()).errors?.pattern &&
                        (callForTenderOfferCreationForm.get(getDailyRateAmountControlName()).dirty ||
                        callForTenderOfferCreationForm.get(getDailyRateAmountControlName()).touched)"
                  message="{{ 'Call_for_tender_offer_creation.input_form_control.field_adr_with_2_decimal' | translate }}"
                ></app-form-feedback>
                <app-form-feedback
                  *ngIf="callForTenderOfferCreationForm.get(getDailyRateAmountControlName()).errors?.dailyRateMin &&
                        (callForTenderOfferCreationForm.get(getDailyRateAmountControlName()).dirty ||
                        callForTenderOfferCreationForm.get(getDailyRateAmountControlName()).touched) &&
                        callForTenderOfferCreationForm.get(getDailyRateAmountControlName()).value !== ''"
                  message="{{ 'Call_for_tender_offer_creation.input_form_control.field_adr_greater' | translate }}"
                ></app-form-feedback>
                <app-form-feedback
                  *ngIf="callForTenderOfferCreationForm.get(getDailyRateAmountControlName()).errors?.invalidRange &&
                        (callForTenderOfferCreationForm.get(getDailyRateAmountControlName()).dirty ||
                        callForTenderOfferCreationForm.get(getDailyRateAmountControlName()).touched) &&
                        callForTenderOfferCreationForm.get(getDailyRateAmountControlName()).value !== ''"
                  [message]="this.checkRates()"
                ></app-form-feedback>
                <app-form-feedback
                  *ngIf="callForTenderOfferCreationForm.get('currency').errors?.required &&
                        (callForTenderOfferCreationForm.get('currency').dirty || callForTenderOfferCreationForm.get('currency').touched ||
                        callForTenderOfferCreationForm.get(getDailyRateAmountControlName()).touched)"
                  message="{{ 'Call_for_tender_offer_creation.input_form_control.field_currency_required' | translate }}"
                ></app-form-feedback>
              </div>
            </div>

            <div class="row">
              <div class="col slide-in-animation">
                <label class="mb-0" for="comment">{{'Call_for_tender_offer_creation.comment'|translate}}<span
                  class="ml-1 text-primary">*</span></label><br>
                  <i>(<span translate>{{'Call_for_tender_offer_creation.provide_anonymized_data'|translate}}</span>)</i>
                <textarea class="form-control mt-2" type="text" formControlName="comment" cols="9"
                          rows="9" id="comment"
                          placeholder="{{'Call_for_tender_offer_creation.write_comment'|translate}}{{selectedCallForTender?.buyerName}}"></textarea>
                <app-form-feedback *ngIf="callForTenderOfferCreationForm.get('comment').errors?.maxlength &&
                (callForTenderOfferCreationForm.get('comment').dirty || callForTenderOfferCreationForm.get('comment').touched)"
                                   message="{{'Call_for_tender_offer_creation.input_form_control.field_maximum_1200'|translate}}">
                </app-form-feedback>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <p><span
                  class="text-primary">*</span> {{'Call_for_tender_offer_creation.field_required'|translate}}
                </p>
              </div>
              <div class="col d-flex justify-content-end align-items-center mb-3">
                <a class="btn btn-outline-primary px-5 mr-3"
                   (click)="backToCftList()">{{'Call_for_tender_offer_creation.cancel'|translate}}</a>
                <button class="btn btn-primary px-5" type="submit"
                        [disabled]="!callForTenderOfferCreationForm.valid">
                  {{'Call_for_tender_offer_creation.submit_btn'|translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h3 class="mb-0"
          >{{'Call_for_tender_offer_creation.portfolio' | translate}}
          <span class="ml-1 text-primary">*</span></h3>
          <i translate>{{'Call_for_tender_offer_creation.resume_must_be_anonymized'|translate}}</i><br>
          <i translate>{{'Call_for_tender_offer_creation.file_explaination'|translate}}</i>
        <div *ngIf="!fileUploaded" class="card box-shadow-hover slide-in-animation mt-3">
          <div class="card-body">
            <file-drop (fileAction)="getFile($event)"></file-drop>
            <div *ngIf="isInUpdateMode" class="d-flex justify-content-end mt-5">
              <button type="button" class="btn btn-link" (click)="backToOldFile()">
                {{'Call_for_tender_offer_creation.revert_file'|translate}}
              </button>
            </div>
          </div>
        </div>
        <ng-container *ngIf="source && fileUploaded">
          <div class="pdf-view">
            <app-pdf-preview [source]="source" [config]="{originalSize: true,page: 0,zoom: 1, scale: 'page-height', showAll: true}"></app-pdf-preview>
          </div>
        </ng-container>
        <div *ngIf="fileUploaded && !offerAlreadyCreated" class="d-flex justify-content-end mt-5">
          <button type="button" class="btn btn-link" (click)="cancelUploadFile()">
            {{(selectedCallForTender?.contractMode === 'T_M'
            ? 'Call_for_tender_offer_creation.upload_resume'
            : 'Call_for_tender_offer_creation.upload_file')| translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #not_day>
  {{'marketplace.by-hour'|translate}}
</ng-template>
