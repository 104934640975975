<div @slideInStagger>
  <div class="row mt-4 slide-in-animation">
    <div class="col-md-12">
      <table class="table table-material box-shadow-hover table-responsive-sm">
        <thead class="thead-normal">
        <tr>
          <th class="text-center">{{"to_generate.column.project"|translate}}</th>
          <th class="text-center">{{"to_generate.column.purchase_order"|translate}}</th>
          <th class="text-center">{{"to_generate.column.period"|translate}}</th>
          <th>{{"to_generate.column.client"|translate}}</th>
          <th class="text-center">{{"to_generate.column.provider"|translate}}</th>
          <th class="text-center">{{"to_generate.column.quantity"|translate}}</th>
          <th class="text-center">{{"to_generate.column.action"|translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let activitySheet of listContent">
          <td class="text-center">
            {{ activitySheet.projectName }}
          </td>
          <td class="text-center">
            {{ activitySheet.purchaseOrderNumber }}
          </td>
          <td class="text-center">
            {{ activitySheet.accountingMonth | date: "MMM yyyy" }}
          </td>
          <td>
            {{ activitySheet.buyerCompanyName }}
          </td>
          <td class="text-center">
            {{ activitySheet.employeeName }}
          </td>
          <td class="text-center">
            {{ activitySheet.sum }} {{ ("global.common." + (activitySheet.type | lowercase)) | translate }}
          </td>
          <td class="text-center">
            <isp-button
              class="px-1"
              [type]="ButtonTypeEnum.ICON"
              [tooltip]="'to_generate.button_generate'|translate"
              [icon]="faPenToSquare"
              [colour]="IspColoursEnum.primary"
              (trigger)="generateInvoice(activitySheet)"
              [size]="SizeEnum.xs"
            ></isp-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row mt-2 slide-in-animation">
    <div class="col-md-12">
      <pagination
        class="float-right"
        [totalItems]="totalElements"
        [maxSize]="5"
        [boundaryLinks]="true"
        [ngModel]="page"
        (pageChanged)="changePage($event)"
        [itemsPerPage]="size"
        previousText="&lsaquo;"
        nextText="&rsaquo;"
        firstText="&laquo;"
        lastText="&raquo;"
      >
      </pagination>
    </div>
  </div>
</div>
