import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {PurchaseOrderService} from '../services/purchase-order-service';
import {PurchaseOrderModel} from '../../../shared/models/purchase-order/purchase-order.model';
import {PurchaseOrderStatusEnum} from '../../../shared/enums/Purchase-order.status.enum';
import {PdfConfigModel} from '../../../shared/models/pdf-config.model';
import {faCalendarDays, faFileText, faDownload, faFileContract} from '@fortawesome/pro-duotone-svg-icons';


import {TranslateService} from '@ngx-translate/core';
import {LocalizedDatePipe} from '../../../shared/pipes/localized-date/localized-date.pipe';
import {ButtonTypeEnum, ExtendedPdfConfigModel, IspColoursEnum} from '@i-supplier/angular-shared-module';

@Component({
  selector: 'purchase-order-view',
  templateUrl: './purchase-order-view.component.html',
  styleUrls: ['./purchase-order-view.component.scss']
})
export class PurchaseOrderViewComponent implements OnInit {
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly IspColours = IspColoursEnum;
  protected readonly faDownload = faDownload;
  itemsList = [];
  selectedPurchaseOrder: PurchaseOrderModel;
  view: string;

  supplierPOFile: any;
  pdfConfig: ExtendedPdfConfigModel;


  constructor(
    private router: Router,
    private purchaseOrderService: PurchaseOrderService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private logger: NGXLogger
  ) {}

  static getPurchaseOrderClass(contract: any): string {
    let titleClass = '';
    switch (contract.status) {
      case PurchaseOrderStatusEnum.EXPIRED: {
        titleClass = 'badge-danger';
        break;
      }
      case PurchaseOrderStatusEnum.REFUSED_BY_SUPPLIER: {
        titleClass = 'badge-danger';
        break;
      }
      case PurchaseOrderStatusEnum.VALIDATED: {
        titleClass = 'badge-success';
        break;
      }
      case PurchaseOrderStatusEnum.CANCELLED: {
        titleClass = 'badge-info';
        break;
      }
      case PurchaseOrderStatusEnum.GENERATED: {
        titleClass = 'badge-purple';
        break;
      }
    }
    return titleClass;
  }

  ngOnInit(): void {
    this.pdfConfig = new ExtendedPdfConfigModel(
      'page-fit',
      '80vh',
      false,
      true,
      false,
      false,
      true,
      true,
      false,
      true,
      true,
      true,
    );
    this.load();
  }

  load(): void {
    this.purchaseOrderService.getPurchaseOrderById(this.activatedRoute.snapshot.paramMap.get('purchaseOrderId')).subscribe(
      (res: any) => {
        // this.logger.info('your po is:', res);
        this.selectedPurchaseOrder = res;

        this.itemsList.push(
          {
            id: 0,
            header: this.translateService.instant('Purchase_orders.request_number'),
            subheader: this.selectedPurchaseOrder.requestNumber,
            status: {icon: faFileText, color: 'tertiary'}
          },
          {
            id: 1,
            header: this.translateService.instant('global.common.creation-date'),
            subheader: (new LocalizedDatePipe(this.translateService)).transform(this.selectedPurchaseOrder.createdDate),
            status: {icon: faCalendarDays, color: 'secondary'}
          },
          {
            id: 2,
            header: this.translateService.instant('Purchase_orders.signatory'),
            subheader: this.selectedPurchaseOrder.hasOwnProperty('recipientFirstName') ? `${this.selectedPurchaseOrder.recipientFirstName} ${this.selectedPurchaseOrder.recipientLastName}` : this.selectedPurchaseOrder.recipientLastName,
            subtext: this.selectedPurchaseOrder.recipientEmail,
            status: {icon: faFileContract, color: 'success'}
          },
        );
        this.view = this.selectedPurchaseOrder.status;

        this.purchaseOrderService.getPurchaseOrderFile(this.selectedPurchaseOrder.id, this.selectedPurchaseOrder.status).subscribe((file: any) => {
            this.supplierPOFile = URL.createObjectURL(new Blob([file.body], {type: file.type}));
          },
          (error: any) => {
            this.logger.error(error.url, '- STATUS :', error.status);
          }
        );


      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        this.router.navigate(['/purchase-orders']).then();
      }
    );
  }

  resolveBadgeStatus(po: any): string {
    return PurchaseOrderViewComponent.getPurchaseOrderClass(po);
  }

  getAlertType() {
    switch (this.selectedPurchaseOrder.status) {
      case PurchaseOrderStatusEnum.GENERATED: {
        return 'info';
      }
      case PurchaseOrderStatusEnum.CANCELLED: {
        return 'warning';
      }
      case PurchaseOrderStatusEnum.VALIDATED: {
        return 'success';
      }
      case PurchaseOrderStatusEnum.REFUSED_BY_SUPPLIER: {
        return 'danger';
      }
      case PurchaseOrderStatusEnum.EXPIRED: {
        return 'warning';
      }
      default : {
        return 'info';
      }
    }
  }

  downloadPurchaseOrder() {
    this.purchaseOrderService.getPurchaseOrderFile(this.selectedPurchaseOrder.id, this.selectedPurchaseOrder.status).subscribe(
      (res: any) => {
        this.purchaseOrderService.handlePurchaseOrderFileDownload(res, this.selectedPurchaseOrder.contractNumber);
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    );
  }

  protected readonly PurchaseOrderStatusEnum = PurchaseOrderStatusEnum;
}
