<div
  [ngClass]="
    fileUploadForm.get('attachmentFileName').errors?.requiredFileType
      ? 'file-drop-wrapper-error'
      : 'file-drop-wrapper'
  "
>
  <ngx-file-drop
    dropZoneClassName="ngx-file-drop-zone"
    contentClassName="ngx-file-drop-content"
    accept=".pdf"
    multiple="false"
    (onFileDrop)="dropped($event)"
    [disabled]="disabled"
    (onFileOver)="fileOver($event)"
    (onFileLeave)="fileLeave($event)"
  >
    <ng-template
      ngx-file-drop-content-tmp
      let-openFileSelector="openFileSelector"
    >
      <div class="d-flex flex-column justify-content-around align-items-center">
        <div class="file-drop-image">
          <img
            alt="Document Upload"
            [src]="
              '../../../../assets/img/file-drop/' +
              (fileUploadForm.get('attachmentFileName').errors?.requiredFileType
                ? 'document_upload_error.svg'
                : 'document_upload.svg')
            "
          />
        </div>

        <div class="file-drop-text">
          {{
            fileUploadForm.get("attachmentFileName").errors?.requiredFileType
              ? ("Legal_documents.input_form_control.field_type_pdf"| translate)
              : ("Legal_documents.drag_document" | translate)
          }}
        </div>

        <div class="file-drop-text">
          {{
            fileUploadForm.get("attachmentFile").errors?.sizeFileExceed &&
              ("Legal_documents.input_form_control.field_maximum_2_mo"| translate)
          }}
        </div>

        <div class="file-drop-text-or-container">
          <div class="file-drop-text-or-border"></div>
          <div class="file-drop-text-or">
            {{ "Legal_documents.or" | translate }}
          </div>
          <div class="file-drop-text-or-border"></div>
        </div>

        <isp-button
          [type]="ButtonTypeEnum.CLASSIC"
          [text]="'Legal_documents.browse_files'| translate"
          [colour]="fileUploadForm.get('attachmentFileName').errors?.requiredFileType ? IspColoursEnum.tertiary : IspColoursEnum.primary"
          [icon]="faFile"
          [disabled]="disabled"
          (trigger)="openFileSelector()"
        ></isp-button>

      </div>
    </ng-template>
  </ngx-file-drop>
</div>
