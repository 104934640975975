<isp-card-template [cardHeader]="purchaseOrder.buyerName" [cardSubHeader]="'N° ' + purchaseOrder.purchaseOrderNumber" [clickable]="true" (trigger)="viewPurchaseOrder()">
  <div content-container>
    <div class="row text-align-center pb-2">
      <div class="col date-section">
        <label for="generation_date">{{'Purchase_orders.common.generation_date' | translate}}</label>
        <div id="generation_date">
          <fa-duotone-icon [icon]="faCalendarCheck"></fa-duotone-icon>
          {{displayDates() ? (purchaseOrder.createdDate|localizedDate) : '--/--/----'}}
        </div>
      </div>
      <div class="col date-section">
        <label for="mission_number">{{'Purchase_orders.mission_number' | translate}}</label>
        <div id="mission_number">
          <fa-duotone-icon [icon]="faHashtag"></fa-duotone-icon>
          {{purchaseOrder.requestNumber}}
        </div>
      </div>
    </div>
    <ng-container *ngIf="displayRefusalReason()">
      <span [tooltip]="purchaseOrder?.reason" [delay]="500">
        <fa-duotone-icon [icon]="faCommentXmark"></fa-duotone-icon>
        {{purchaseOrder.reason.length > 28 ? (purchaseOrder.reason | slice: 0:28) + " ..." : purchaseOrder.reason}}
      </span>
    </ng-container>
  </div>
  <div actions-left-container>
      <span [class]="ispBadgeColoursService.statusBadgeMapper(purchaseOrder.status)">
        {{'Purchase_orders.common.status.' + purchaseOrder.status | translate}}
      </span>
  </div>
  <div actions-right-container>
    <isp-button *ngIf="[PurchaseOrderStatusEnum.VALIDATED, PurchaseOrderStatusEnum.EXPIRED].includes(purchaseOrder.status)" [type]="ButtonTypeEnum.ICON" [icon]="faDownload" [colour]="IspColours.primary" [tooltip]="'global.button.download'|translate" (trigger)="downloadPurchaseOrder()"></isp-button>
  </div>
</isp-card-template>
