<app-header
  [navbarBrandRouterLink]="['/']"
  [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/isupplier-full.svg', width: 120, height: 30, alt: 'iSupplier Logo'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/icon-isupplier.svg', width: 40, height: 40, alt: 'iSupplier Logo'}"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]=false
  [ngClass]="{'devmode': !['prod', 'demo'].includes(environment.env)}">
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item badge badge-danger" *ngIf="!['prod', 'demo'].includes(environment.env)">ENV : {{ environment.env }}</li>
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link profile" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
         aria-expanded="false"
         dropdownToggle (click)="false">
        <span>{{username | titlecase}}</span>
        <ngx-avatars [name]="username" [src]="imageSrc" bgColor="#3498DB1A" fgColor="#3498DB" size="35" textSizeRatio="2"></ngx-avatars>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu
           aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center">
          <strong>{{'global.profile-menu.settings'|translate}}</strong>
        </div>
        <a class="dropdown-item" [routerLink]="['profile/my-profile']">
          <fa-icon class="pr-2" [icon]="faUser"></fa-icon>{{'global.profile-menu.profile'|translate}}
        </a>
        <a class="dropdown-item" *ngIf="isNotProvider()" [routerLink]="['company-config/legal-information']">
          <fa-icon class="pr-2" [icon]="faWrench"></fa-icon>{{'global.profile-menu.company'|translate}}
        </a>
        <div class="divider"></div>
        <a class="dropdown-item cursor-pointer" (click)="logout()">
          <fa-icon class="pr-2" [icon]="faLock"></fa-icon>{{'global.profile-menu.logout'|translate}}
        </a>
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
               (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav
      [navItems]="navItems"
      perfectScrollbar
      [disabled]="appSidebar.minimized"
    ></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <div *ngIf="isCompanyIncomplete()" class="box-red-border-for-default-layout box-shadow-hover">
      <div class="row">
        <div class="col-9 notif-container">
          <div class="notif-provider">{{'Legal_information.incomplete_notif'|translate}}</div>
          <div class="notif-provider-guide">
            <a class="nav-link" [routerLink]="['company-config/legal-information']"
               routerLinkActive="active"
               [routerLinkActiveOptions]="{exact: false}">
              {{'Legal_information.incomplete_notif_guide'|translate}}
            </a>

          </div>
        </div>
      </div>
    </div>

    <!-- Breadcrumb -->
    <breadcrumbs></breadcrumbs>

    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>

</div>
<app-footer>
  <span><a href="https://isupplier.fr">iSupplier</a> &copy; 2024</span>
  <span class="ml-auto"><a (click)="showCGU()" class="cgu">{{'app.show-cgu'|translate}}</a></span>
  <span class="ml-auto">Powered by <a href="https://coreui.io/angular">CoreUI for Angular</a></span>
</app-footer>

