import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {ActivityReportService} from "../../views/activity-report/services/activity-report.service";
import {NGXLogger} from "ngx-logger";
import {PurchaseOrderService} from "../../views/purchase-order/services/purchase-order-service";
import {ContractService} from "../../views/contract/services/contract.service";

@Injectable({
  providedIn: 'root'
})
export class BadgesService {

  private menuItemKeyInvoiceToGenerate;
  private menuItemKeyContract;
  private menuItemKeyPurchaseOrder;

  private badgesChangedSource = new Subject<BadgeWithKey>();
  badgesChanged$ = this.badgesChangedSource.asObservable();

  constructor(
    private activityReportService: ActivityReportService,
    private purchaseOrderService: PurchaseOrderService,
    private contractService: ContractService,
    private logger: NGXLogger
  ) {
  }

  refreshInvoiceToGenerateBadge() {
    this.loadInvoiceToGenerateBadge(this.menuItemKeyInvoiceToGenerate);
  }

  loadInvoiceToGenerateBadge(itemKey: string) {
    if (itemKey) {
      this.menuItemKeyInvoiceToGenerate = itemKey;
      this.activityReportService.getPendingInvoicesCount().subscribe({
        next: invoiceCount => {
          if (invoiceCount.total && invoiceCount.total > 0) {
            this.badgesChangedSource.next({
              key: itemKey,
              badge: {text: invoiceCount.total, variant: 'danger', class: ''}
            });
          } else {
            this.badgesChangedSource.next({key: itemKey, badge: undefined});
          }
        },
        error: () => {
          this.badgesChangedSource.next({key: itemKey, badge: undefined});
        }
      });
    }
  }

  refreshPurchaseOrderBadge() {
    this.loadPurchaseOrderBadge(this.menuItemKeyPurchaseOrder);
  }

  loadPurchaseOrderBadge(itemKey: string) {
    if (itemKey) {
      this.menuItemKeyPurchaseOrder = itemKey;
      this.purchaseOrderService.getPurchaseOrdersWaiting().subscribe(res => {
        if (res.count && res.count > 0) {
          this.badgesChangedSource.next({
            key: itemKey,
            badge: {text: res.count, variant: 'danger', class: ''}
          });
        } else {
          this.badgesChangedSource.next({key: itemKey, badge: undefined});
        }
      }, error => {
        this.logger.error(error.url, '- Count :', error.status);
        this.badgesChangedSource.next({key: itemKey, badge: undefined});
      });
    }
  }

  refreshContractBadge() {
    this.loadContractBadge(this.menuItemKeyContract)
  }

  loadContractBadge(itemKey: string) {
    if (itemKey) {
      this.menuItemKeyContract = itemKey;
      this.contractService.getContractsWaiting().subscribe(res => {
        if (res.count && res.count > 0) {
          this.badgesChangedSource.next({
            key: itemKey,
            badge: {text: res.count, variant: 'danger', class: ''}
          });
        } else {
          this.badgesChangedSource.next({key: itemKey, badge: undefined});
        }
      }, error => {
        this.logger.error(error.url, '- Count :', error.status);
        this.badgesChangedSource.next({key: itemKey, badge: undefined});
      });
    }
  }
}

export class Badge {
  text: string;
  variant: string;
  class: string;
}

export class BadgeWithKey {
  key: string;
  badge: Badge;
}
