<div class="animated fadeIn mb-5">
  <div class="row">
    <div class="col-md-12">
      <div class="header">{{"credit_note_proforma_generation.title"|translate}}</div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-9">
      <div class="pdf-viewer-container box-shadow-hover">
        <app-spinner *ngIf="loaders['invoiceProformaGenerationFormSubmit']" [display]="loaders['invoiceProformaGenerationFormSubmit']"></app-spinner>
        <div class="pdf-view">
          <app-pdf-preview
            *ngIf="pdf_source['proformaFile']; else noPreview"
            [source]="pdf_source['proformaFile']"
            [config]="{originalSize: true,page: 0,zoom: 1, scale: 'page-height', showAll: true}"

          ></app-pdf-preview>
        </div>

        <ng-template #noPreview>
          <div *ngIf="!loaders['invoiceProformaGenerationFormSubmit']" class="invoice-image">
            <img src="assets/img/proforma/invoice.svg" alt="Document Upload"/>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="col-md-3">
      <!--      Generate Proforma-->
      <div
        *ngIf="!pdf_source['proformaFile']"
        class="generate-proforma box-shadow-hover text-align-center"
      >
        <div class="box-header">{{"credit_note_proforma_generation.section"|translate}}</div>

        <form *ngIf="invoiceProformaGenerationForm" [formGroup]="invoiceProformaGenerationForm" (ngSubmit)="invoiceProformaGenerationFormSubmit()">
          <div class="mlr-auto mt-2 wid-90">
            <mat-form-field class="wid-100 text-align-center">
              <mat-label>{{"credit_note_proforma_generation.edit.invoice_number"|translate}}</mat-label>
              <input matInput formControlName="invoiceNumber" [placeholder]='"credit_note_proforma_generation.edit.invoice_number_placeholder"|translate' required/>
              <button
                type="button"
                mat-button
                matSuffix
                mat-icon-button
                aria-label="Clear"
                *ngIf="canDisplay(invoiceProformaGenerationForm, 'invoiceNumber')"
                (click)="clearInputField(invoiceProformaGenerationForm,'invoiceNumber')"
              >
                <fa-icon [icon]="faTimes"></fa-icon>
              </button>
              <mat-error
                *ngIf="invoiceProformaGenerationForm.get('invoiceNumber').errors?.required"
                [innerHTML]="'credit_note_proforma_generation.feedback.required_field' | translate"
              ></mat-error>
              <mat-error
                *ngIf="invoiceProformaGenerationForm.get('invoiceNumber').errors?.pattern"
                [innerHTML]="'credit_note_proforma_generation.feedback.noLeadingTrailingSpacesPattern' | translate"
              ></mat-error>
            </mat-form-field>
            <mat-form-field class="wid-100 text-align-center">
              <mat-label>{{"credit_note_proforma_generation.edit.reason"|translate}}</mat-label>
              <textarea matInput formControlName="reason"
                [placeholder]="'credit_note_proforma_generation.edit.reason_placeholder'|translate" required>
              </textarea>
              <button
                type="button"
                mat-button
                matSuffix
                mat-icon-button
                aria-label="Clear"
                *ngIf="canDisplay(invoiceProformaGenerationForm, 'reason')"
                (click)="clearInputField(invoiceProformaGenerationForm,'reason')"
              >
                <fa-icon [icon]="faTimes"></fa-icon>
              </button>
              <mat-error
                *ngIf="invoiceProformaGenerationForm.get('reason').errors?.required"
                [innerHTML]="'credit_note_proforma_generation.feedback.required_field' | translate"
              ></mat-error>
            </mat-form-field>
          </div>

          <div class="pt-2 text-align-center">
            <isp-button
              [type]="ButtonTypeEnum.CLASSIC"
              [action]="ButtonActionEnum.SUBMIT"
              [colour]="IspColoursEnum.primary"
              [text]="'credit_note_proforma_generation.button_generate'"
              [loader]="loaders['invoiceProformaGenerationFormSubmit']"
              [disabled]="invoiceProformaGenerationForm.invalid || pdf_source['proformaFile'] != null"
              [style]="'w-90'"
            ></isp-button>
          </div>
        </form>
      </div>

      <div *ngIf="pdf_source['proformaFile']">
        <form
          *ngIf="invoiceTransmitForm"
          [formGroup]="invoiceTransmitForm"
          (ngSubmit)="invoiceTransmitFormSubmit()"
        >
          <!--      Submit Invoice-->
          <ng-container *ngIf="!manualInvoiceFileToUpload">
            <div class="submit-buttons">
              <isp-button
                [type]="ButtonTypeEnum.CLASSIC"
                [action]="ButtonActionEnum.SUBMIT"
                [colour]="IspColoursEnum.success"
                [text]="'credit_note_proforma_generation.button_validate'"
                [loader]="loaders['invoiceTransmitFormSubmit']"
                [style]="'wide'"
              ></isp-button>

              <div class="d-flex justify-content-between mt-3">
                <isp-button
                  [type]="ButtonTypeEnum.CLASSIC"
                  [colour]="IspColoursEnum.primary"
                  (trigger)="openModal()"
                  [text]="'credit_note_proforma_generation.button_upload'"
                ></isp-button>

                <isp-button
                  [type]="ButtonTypeEnum.CLASSIC"
                  [colour]="IspColoursEnum.secondary"
                  (trigger)="cancelProforma()"
                  [text]="'credit_note_proforma_generation.button_go_back'"
                ></isp-button>
              </div>
            </div>
          </ng-container>

          <!--      Manual Invoice Form-->
          <ng-container *ngIf="manualInvoiceFileToUpload">
            <div class="manual-form-wrapper box-shadow-hover">
              <div class="box-header ml-3">
                {{ "credit_note_proforma_generation.label_details_manual_invoice" | translate }}
              </div>

              <div class="mlr-auto mt-2 wid-90">
                <label class="mb-1 float-left" for="date-input">{{'credit_note_proforma_generation.edit.invoice_date' | translate}}</label>
                <input type="text" class="form-control" id="date-input" bsDatepicker readonly formControlName="creditNoteDate"
                       [placeholder]="'credit_note_proforma_generation.edit.select_date'|translate"
                       [bsConfig]="{
                       minDate: getInvoiceDate(),
                       maxDate:currentDate,
                       dateInputFormat:'DD/MM/YYYY',
                       showPreviousMonth:false,
                       containerClass:'theme-blue'}">
              </div>

              <div class="mlr-auto mt-2 wid-90">
                <mat-form-field class="wid-100 text-align-center">
                  <mat-label>{{ "credit_note_proforma_generation.edit.taxless_total_amount" | translate }}</mat-label>
                  <input
                    type="number"
                    matInput
                    max="0"
                    formControlName="taxlessTotal"
                    [placeholder]="'credit_note_proforma_generation.edit.taxless_total_amount_placeholder'|translate"
                    required
                  />
                  <button
                    mat-button
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    *ngIf="canDisplay(invoiceTransmitForm, 'taxlessTotal')"
                    (click)="clearInputField(invoiceTransmitForm, 'taxlessTotal')"
                  >
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </button>
                  <mat-error *ngIf="invoiceTransmitForm.get('taxlessTotal').errors?.required" [innerHTML]="'credit_note_proforma_generation.feedback.required_field' | translate"></mat-error>
                  <mat-error *ngIf="invoiceTransmitForm.get('taxlessTotal').errors?.max" [innerHTML]="'credit_note_proforma_generation.feedback.negative_field' | translate"></mat-error>
                </mat-form-field>
              </div>

              <div class="mlr-auto mt-2 wid-90">
                <mat-form-field class="wid-100 text-align-center">
                  <mat-label>{{ "credit_note_proforma_generation.edit.tax_amount" | translate }}</mat-label>
                  <input type="number" matInput max="0"
                    formControlName="taxAmount"
                    [placeholder]="'credit_note_proforma_generation.edit.tax_amount_placeholder'|translate"
                    required/>
                  <button
                    mat-button
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    *ngIf="canDisplay(invoiceTransmitForm, 'taxAmount')"
                    (click)="clearInputField(invoiceTransmitForm, 'taxAmount')"
                  >
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </button>
                  <mat-error *ngIf="invoiceTransmitForm.get('taxAmount').errors?.required" [innerHTML]="'credit_note_proforma_generation.feedback.required_field' | translate"></mat-error>
                  <mat-error *ngIf="invoiceTransmitForm.get('taxAmount').errors?.max" [innerHTML]="'credit_note_proforma_generation.feedback.negative_field' | translate"></mat-error>
                </mat-form-field>
              </div>

              <div class="mlr-auto mt-2 wid-90">
                <mat-form-field class="wid-100 text-align-center">
                  <mat-label>{{"credit_note_proforma_generation.edit.total_incl_tax"|translate}}</mat-label>
                  <input type="number" matInput max="0"
                    formControlName="totalIncludingTax"
                    [placeholder]="'credit_note_proforma_generation.edit.total_incl_tax_placeholder'|translate"
                    required/>
                  <button
                    mat-button
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    *ngIf="canDisplay(invoiceTransmitForm, 'totalIncludingTax')"
                    (click)="
                      clearInputField(invoiceTransmitForm, 'totalIncludingTax')
                    "
                  >
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </button>
                  <mat-error *ngIf="invoiceTransmitForm.get('totalIncludingTax').errors?.required" [innerHTML]="'credit_note_proforma_generation.feedback.required_field' | translate"></mat-error>
                  <mat-error *ngIf="invoiceTransmitForm.get('totalIncludingTax').errors?.max" [innerHTML]="'credit_note_proforma_generation.feedback.negative_field' | translate"></mat-error>
                </mat-form-field>
              </div>

              <div class="pb-3 pt-1 text-align-center">
                <isp-button
                  [type]="ButtonTypeEnum.CLASSIC"
                  [action]="ButtonActionEnum.SUBMIT"
                  [colour]="IspColoursEnum.success"
                  [text]="'credit_note_proforma_generation.button_validate'"
                  [loader]="loaders['invoiceTransmitFormSubmit']"
                  [disabled]="invoiceTransmitForm.invalid"
                  [style]="'w-90'"
                ></isp-button>
              </div>
            </div>

            <div class="py-3">
              <isp-button
                [type]="ButtonTypeEnum.CLASSIC"
                [colour]="IspColoursEnum.secondary"
                [text]="'credit_note_proforma_generation.button_go_back'"
                (trigger)="clearFile()"
                [style]="'wide'"
              ></isp-button>
            </div>
          </ng-container>
        </form>
      </div>

      <!--      Invoice Info-->
      <div class="invoice-info box-shadow-hover" *ngIf="invoice">
        <div class="box-header text-align-center">
          {{ "credit_note_proforma_generation.label_invoice_info" | translate }}
        </div>

        <ng-container>
          <hr/>
          <div class="invoice-info-content">
            <div class="ellipse-icon d-xl-block d-md-none d-block" style="background-color: #00108033">
              <mat-icon class="mat-icon-list" style="color: #001080BF">
                summarize
              </mat-icon>
            </div>
            <div>
              <div
                class="invoice-info-content-header">{{ "credit_note_proforma_generation.label_invoice_invoice" | translate }}</div>
              <div class="invoice-info-content-subheader">
                {{ invoice.number }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container>
          <hr/>
          <div class="invoice-info-content">
            <div class="ellipse-icon d-xl-block d-md-none d-block" style="background-color: #f1c40f33">
              <mat-icon class="mat-icon-list" style="color: #E7B505BF">
                summarize
              </mat-icon>
            </div>
            <div>
              <div
                class="invoice-info-content-header">{{ "credit_note_proforma_generation.label_invoice_purchase_order" | translate }}</div>
              <div class="invoice-info-content-subheader">
                {{ invoice.purchaseOrders.join(', ') }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container>
          <hr/>
          <div class="invoice-info-content">
            <div class="ellipse-icon d-xl-block d-md-none d-block" style="background-color: #9b59b633">
              <mat-icon class="mat-icon-list" style="color: #9B59B6">
                person
              </mat-icon>
            </div>
            <div>
              <div
                class="invoice-info-content-header">{{ "credit_note_proforma_generation.label_invoice_client" | translate }}</div>
              <div class="invoice-info-content-subheader">
                {{ invoice.buyers.join(', ') }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container>
          <hr/>
          <div class="invoice-info-content">
            <div class="ellipse-icon d-xl-block d-md-none d-block" style="background-color: #504E4B33">
              <mat-icon class="mat-icon-list" style="color: #343a40">
                badge
              </mat-icon>
            </div>
            <div>
              <div
                class="invoice-info-content-header">{{ "credit_note_proforma_generation.label_invoice_employee" | translate }}</div>
              <div class="invoice-info-content-subheader">
                {{ invoice.employees.join(', ') }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
