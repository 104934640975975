<div class="animated fadeIn">
  <div class="row">
    <div class="col-md-12">
      <h2 class="underline">{{'Call_for_tender_offer_list.offer_list_title'|translate}}</h2>
    </div>
  </div>
  <ng-container *ngIf="!firstCallDone; else firstCallDoneBlock">
    <app-spinner [backgroundTransparent]="true"></app-spinner>
  </ng-container>
  <ng-template #firstCallDoneBlock>
    <div *ngIf="!isListEmpty; else noCallForTenderOffersBlock" @slideInStagger>
      <div class="row">
        <div class="col-md-3">
          <div class="card box-shadow-hover mb-2 slide-in-animation">
            <div class="card-body">
              <div class="row">
                <div class="col-md-9 form-group pr-1">
                  <label for="sort">{{'Call_for_tender_offer_list.sort_by.sort_by_title'|translate}}</label>
                  <select class="custom-select" id="sort" (change)="setSort(sortSelect.value)" [value]="sort"
                          #sortSelect>
                    <option
                      value="modifiedDate">{{'Call_for_tender_offer_list.sort_by.modified_date'|translate}}</option>
                    <option value="createdDate">{{'Call_for_tender_offer_list.sort_by.created_date'|translate}}</option>
                  </select>
                </div>
                <div class="col-md-3 form-group d-flex align-items-end pl-1">
                  <button class="btn btn-secondary w-100" type="button"
                          (click)="setSortDirection()" [title]="sortDirection">
                    <i class="fa fa-sort-amount-desc fa-flip-vertical" aria-hidden="true"
                       *ngIf="sortDirection === 'asc'"></i>
                    <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="sortDirection === 'desc'"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <form [formGroup]="advancedSearchForm" (ngSubmit)="advancedSearchFormSubmit()">
            <div class="card box-shadow-hover mb-2 slide-in-animation">
              <div class="card-body">
                <div class="form-group">
                  <label for="reference">{{'Call_for_tender_offer_list.reference'|translate}}</label>
                  <input type="text" class="form-control" id="reference" formControlName="reference">
                </div>
                <div class="row">
                  <div class="col form-group">
                    <label for="title">{{'Call_for_tender_offer_list.title'|translate}}</label>
                    <input type="text" class="form-control" id="title" formControlName="title">
                  </div>
                </div>
                <div class="row" *ngIf="userRole !== 'ROLE_FREELANCER'">
                  <div class="col form-group">
                    <label for="provider">{{'Provider'|translate}}</label>
                    <input type="text" class="form-control" id="provider" formControlName="provider">
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group">
                    <label for="buyer">{{'Call_for_tender_offer_list.buyer'|translate}}</label>
                    <input type="text" class="form-control" id="buyer" formControlName="buyer">
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group">
                    <label for="status">{{'Call_for_tender_offer_list.status'|translate}}</label>
                    <ng-select [items]="statusSearchable"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               [hideSelected]="false"
                               [labelForId]="'status'"
                               [searchable]="false"
                               formControlName="status"
                               id="status"
                               class="ng-select-custom">
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [checked]="item$.selected"/>
                        {{item | titlecase | statusReadable}}
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items ">
                          <span class="ng-value-label">{{item | titlecase | statusReadable}}</span>
                        </div>
                        <div class="ng-value" *ngIf="items.length > 2">
                          <span class="ng-value-label">{{items.length - 2}} more...</span>
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end align-items-center slide-in-animation">
              <button class="btn btn-link" type="button"
                      (click)="resetAdvancedSearchForm()">{{'Call_for_tender_offer_list.reset_btn'|translate}}</button>
              <button class="btn btn-primary px-5" type="submit"
                      [disabled]="advancedSearchForm.pristine">{{'Call_for_tender_offer_list.apply_filter_btn'|translate}}
              </button>
            </div>
          </form>
        </div>
        <div class="col-md-9">
          <p class="text-dark-gray mb-1 text-right"
             *ngIf="totalElements > 0">{{'Call_for_tender_offer_list.showing'|translate}}
            <strong>{{numberOfElements}}</strong> {{'Call_for_tender_offer_list.of'|translate}}
            <strong>{{totalElements}}</strong>
            {{'Call_for_tender_offer_list.entries'|translate}}</p>
          <div *ngIf="listContent.length > 0; else noResult" infinite-scroll
               [infiniteScrollDistance]="2"
               [infiniteScrollThrottle]="300"
               [scrollWindow]="true"
               (scrolled)="getNextPageCallForTenderList()">
            <app-call-for-tender-offers-list-card class="slide-in-animation d-block"
                                                  *ngFor="let callForTenderOffer of listContent"
                                                  [callForTenderOffer]="callForTenderOffer"
                                                  (callForTenderOfferDetail)="deleteOffer($event)"
                                                  (callForTenderOfferTimeSlot)="showOfferTimeSlotsModel($event)"
                                                  (callForTenderOfferNegotiation)="showNegotiationModal($event)">
            </app-call-for-tender-offers-list-card>
          </div>
        </div>

        <ng-template #noResult>
          <div class="text-center my-3 slide-in-animation" @slideIn>
            <img src="assets/img/result_empty.svg" alt="No results" style="width: 30rem;">
            <h5 class="font-weight-bold mt-3">{{'Call_for_tender_offer_list.no_result'|translate}}</h5>
          </div>
        </ng-template>
      </div>
    </div>

    <ng-template #noCallForTenderOffersBlock>
      <app-no-content class="d-block" content="{{'Call_for_tender_offer_list.no_offers'|translate}}"
                      iconClassName="fa fa-newspaper-o" @slideIn>
      </app-no-content>
    </ng-template>
  </ng-template>


  <div class="modal fade" bsModal #offerTimeSlotsModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-lg modal-right">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'Call_for_tender_offer_list.meeting-title'|translate }}</h4>
      </div>
      <div class="modal-content">
        <div class="row mt-5">
          <div class="col d-flex justify-content-center">
            <h3>{{callForTenderDetailModalValue.title}} - {{callForTenderDetailModalValue.reference}}</h3>
          </div>
          <div class="w-100"></div>
          <div class="col d-flex justify-content-center">
            <p class="mb-1" title="ADR">
              <ng-container *ngIf="callForTenderDetailModalValue.contractMode === 'FIXED_PRICE' ? (callForTenderDetailModalValue.minAmount !== 0 && callForTenderDetailModalValue.minAmount !== callForTenderDetailModalValue.maxAmount)
            : (callForTenderDetailModalValue.minDailyRate !== 0 && callForTenderDetailModalValue.minDailyRate !== callForTenderDetailModalValue.maxDailyRate)">
                {{callForTenderDetailModalValue.contractMode === 'FIXED_PRICE' ? callForTenderDetailModalValue.minAmount : callForTenderDetailModalValue.minDailyRate}}{{callForTenderDetailModalValue.currency | currencyFormat: 'symbol'}}
                - </ng-container>
              {{callForTenderDetailModalValue.contractMode === 'FIXED_PRICE' ? callForTenderDetailModalValue.maxAmount : callForTenderDetailModalValue.maxDailyRate}}{{callForTenderDetailModalValue.currency | currencyFormat: 'symbol'}}
              <span *ngIf="callForTenderDetailModalValue.dailyRateByDate;else not_day">
                    {{'marketplace.by-day'|translate}}
                  </span>
            </p>
          </div>
        </div>

        <div class="modal-body mt-5">
          <hr/>
          <div class="row mb-5">
            <div class="col col d-flex justify-content-center mb-2 mt-5">
              <h4 class="mr-2">
            <span
              [title]="callForTenderOfferDetailModalValue.title">{{callForTenderOfferDetailModalValue.providerFirstName}} {{callForTenderOfferDetailModalValue.providerLastName | uppercase}}
              ({{callForTenderOfferDetailModalValue.nickName | uppercase}}
              ) - {{callForTenderOfferDetailModalValue.title }}</span>
              </h4>
            </div>
            <div class="w-100"></div>
            <div class="col d-flex justify-content-center">
              <span class="mr-3" [title]="'activity-area.title'|translate">
                <i class="fa fa-industry mr-1" aria-hidden="true"></i>
                {{ callForTenderOfferDetailModalValue.activityArea?.label }}
              </span>
              <span class="mr-3" title="Start date">
                <i class="fa fa-calendar mr-1" aria-hidden="true"></i>
                {{callForTenderDetailModalValue.startDate | localizedDate}}
              </span>
              <span class="mr-3" title="Mission duration">
                <i class="fa fa-clock-o mr-1" aria-hidden="true"></i>
                {{callForTenderDetailModalValue.quantity}} {{'global.common.'+(callForTenderDetailModalValue.quantityByDate?'days':'hours')|translate}}
              </span>
              <span class="mr-3" title="Workplace">
                <i class="fa fa-map-marker mr-1" aria-hidden="true"></i>
                {{callForTenderDetailModalValue.workplace.city}} - {{callForTenderDetailModalValue.workplace.country}}
              </span>
            </div>
            <div class="w-100"></div>
            <div class="col d-flex justify-content-center mt-2">
              <span *ngFor="let technology of callForTenderDetailModalValue.technologies" class="badge badge-gray mr-1">
                {{technology}}
              </span>
            </div>
            <div class="w-100 mt-5">
              <hr/>
            </div>

            <div class="col">
              <form [formGroup]="meetingsForm" (ngSubmit)="patchCallForTenderOffer()">
                <div *ngFor="let meeting of sortedMeetings" [formGroupName]="meeting.name">
                  <div class="row" *ngIf="meeting.group.date">
                    <div class="col-md-4 form-group pr-1">
                      <h5>{{ meeting.group.date | localizedDate }} - {{ meeting.group.time }}</h5>
                    </div>
                    <div class="col form-group pl-1">
                      <button class="card-btn btn-reject float-right ml-1" type="button" title="Reject Time slot"
                              (click)="rejectTimeSlot(meeting); $event.stopPropagation()"
                              [disabled]="meeting.group.status === 'REFUSED'">
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </button>
                      <button class="card-btn btn-select float-right" type="button" title="Accept Time slot"
                              (click)="acceptTimeSlot(meeting); $event.stopPropagation()"
                              [disabled]="meeting.group.status === 'ACCEPTED'">
                        <i class="fa fa-check" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-center align-items-center mt-1">
                  <button class="btn btn-outline-warning px-5 mr-3" type="button" (click)="hideModal()">
                    {{'Call_for_tender_offer_list.cancel_btn'|translate}}
                  </button>
                  <button class="btn btn-primary px-5" type="submit" [disabled]="meetingsForm.invalid">
                    {{'Call_for_tender_offer_list.save_btn'|translate}}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!--NEGOTIATION MODAL PART-->
  <div class="modal fade" bsModal #offerNegotiationModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-lg modal-right">
      <div class="modal-content">
        <div class="row mt-5">
          <div class="col d-flex justify-content-center">
            <h3>{{callForTenderDetailModalValue.title}} - {{callForTenderDetailModalValue.reference}}</h3>
          </div>
          <div class="w-100"></div>
          <div class="col d-flex justify-content-center">
            <p class="mb-1" title="ADR">
              <ng-container *ngIf="callForTenderDetailModalValue.contractMode === 'FIXED_PRICE' ? (callForTenderDetailModalValue.minAmount && callForTenderDetailModalValue.minAmount !== 0 && callForTenderDetailModalValue.minAmount !== callForTenderDetailModalValue.maxAmount) : (callForTenderDetailModalValue.minDailyRate && callForTenderDetailModalValue.minDailyRate !== 0 && callForTenderDetailModalValue.minDailyRate !== callForTenderDetailModalValue.maxDailyRate)">
                {{callForTenderDetailModalValue.contractMode === 'FIXED_PRICE' ? callForTenderDetailModalValue.minAmount : callForTenderDetailModalValue.minDailyRate}}{{callForTenderDetailModalValue.currency | currencyFormat: 'symbol'}} -
              </ng-container>
              {{callForTenderDetailModalValue.contractMode === 'FIXED_PRICE' ? callForTenderDetailModalValue.maxAmount : callForTenderDetailModalValue.maxDailyRate}}{{callForTenderDetailModalValue.currency | currencyFormat: 'symbol'}}
              <span *ngIf="callForTenderDetailModalValue.dailyRateByDate;else not_day">
                {{'marketplace.by-day'|translate}}
              </span>
            </p>
          </div>
        </div>

        <div class="modal-body mt-5">
          <hr/>
          <div class="row mb-5">
            <div class="col col d-flex justify-content-center mb-2 mt-5">
              <h4 class="mr-2">
                <span [title]="callForTenderOfferDetailModalValue.title">
                  {{callForTenderOfferDetailModalValue.providerFirstName}} {{callForTenderOfferDetailModalValue.providerLastName | uppercase}} ({{callForTenderOfferDetailModalValue.nickName | uppercase}}) - {{callForTenderOfferDetailModalValue.title }}
                </span>
              </h4>
            </div>
            <div class="w-100"></div>
            <div class="col d-flex justify-content-center">
              <span class="mr-3" [title]="'activity-area.title'|translate">
                <i class="fa fa-industry mr-1" aria-hidden="true"></i>
                {{ callForTenderOfferDetailModalValue.activityArea?.label }}
              </span>
              <span class="mr-3" title="Start date">
                <i class="fa fa-calendar mr-1" aria-hidden="true"></i>
                {{callForTenderDetailModalValue.startDate | localizedDate: 'dd MMM yyyy'}}
              </span>
              <span class="mr-3" title="Mission duration">
                <i class="fa fa-clock-o mr-1" aria-hidden="true"></i>
                {{callForTenderDetailModalValue.quantity}} {{(callForTenderDetailModalValue.quantityByDate ? 'global.common.days' : 'global.common.hours') | translate}}
              </span>
              <span class="mr-3" title="Workplace">
                <i class="fa fa-map-marker mr-1" aria-hidden="true"></i>
                {{callForTenderDetailModalValue.workplace.city}} - {{callForTenderDetailModalValue.workplace.country}}
              </span>
            </div>
            <div class="w-100"></div>
            <div class="col d-flex justify-content-center mt-2">
              <span *ngFor="let technology of callForTenderDetailModalValue.cftSkills" class="badge badge-gray mr-1">
                {{technology['name']}}
              </span>
            </div>
            <div class="w-100 mt-5">
              <hr/>
            </div>

            <div class="col d-flex justify-content-center align-items-center mt-4">
              <form [formGroup]="negotiationForm" (ngSubmit)="onFormSubmit()">
                <h4 class="font-weight-bold mb-2 d-flex justify-content-center">
                  {{'call_for_tender_negotiation_card.proposed_negotiation'|translate}} : {{callForTenderOfferDetailModalValue.priceNegotiation + ' ' +(callForTenderDetailModalValue.currency | currencyFormat: 'symbol')}}
                </h4>

                <div class="d-flex justify-content-center mt-3">
                  <button class="btn btn-outline-success" (click)="setButtonClicked('accept')">
                    {{'call_for_tender_negotiation_card.accept_negotiation'|translate}}
                  </button>
                </div>

                <div class="d-flex justify-content-center mt-3">
                  <h4 class="font-weight-bold mb-2">
                    {{'call_for_tender_negotiation_card.or'|translate|uppercase}}
                  </h4>
                </div>

                <div *ngIf="callForTenderOfferDetailModalValue?.auditRecords?.length == 2">
                  <div class="d-flex justify-content-center mt-3">
                      <span style="font-weight: 500;" class="mt-1">
                        {{'call_for_tender_negotiation_card.counter_proposal'|translate}} :
                      </span>
                    <input type="number" class="form-control-md mx-2" id="negotiateAmount" formControlName="amount"
                           placeholder="{{callForTenderOfferDetailModalValue.priceNegotiation}}">
                    <span class="ml-3">
                        <button class="btn btn-outline-info" type="submit" [disabled]="!negotiationForm.valid" (click)="setButtonClicked('negotiate')">
                          {{'call_for_tender_negotiation_card.negotiate'|translate}}
                        </button>
                      </span>
                  </div>

                  <div class="d-flex justify-content-center">
                    <app-form-feedback
                      *ngIf="negotiationForm.get('amount').errors?.required && (negotiationForm.get('amount').dirty || negotiationForm.get('amount').touched)"
                      [message]="'global.feedback.required'|translate"
                    ></app-form-feedback>
                    <app-form-feedback
                      *ngIf="negotiationForm.get('amount').errors?.amountRange && negotiationForm.get('amount').value"
                      [message]="'global.feedback.offer-negotiation-range'|translate"
                    ></app-form-feedback>
                  </div>

                  <div class="d-flex justify-content-center ml-2" style="font-weight: 500;">
                    {{'call_for_tender_negotiation_card.between-min-max'|translate :
                    {
                      min: minAmount,
                      max: maxAmount,
                      currency:this.callForTenderDetailModalValue.currency | currencyFormat: 'symbol'
                    }
                    }}
                  </div>
                </div>

                <div *ngIf="callForTenderOfferDetailModalValue?.auditRecords?.length == 4">
                  <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-outline-primary" (click)="setButtonClicked('closed')">
                      {{'call_for_tender_negotiation_card.stop_negotiation'|translate}}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #not_day>
  {{'marketplace.by-hour'|translate}}
</ng-template>
