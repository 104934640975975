export class AdministrativeManagerModel {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;

  constructor(object?: any) {
    if (object) {
      this.firstName = object.firstName;
      this.lastName = object.lastName;
      this.email = object.email;
      this.phoneNumber = object.phoneNumber;
    }
  }
}

export class CompanyCreationBodyModel {
  name: string;
  type: string;
  origin: string;
  incorporationNumber: string;
  administrativeManager: AdministrativeManagerModel;
  hasDpo: boolean;
  firstNameDpo: string;
  lastNameDpo: string;
  emailDpo: string;

  constructor(object?: any) {
    if (object) {
      this.name = object.name;
      this.type = object.type;
      this.origin = object.origin;
      this.incorporationNumber = object.incorporationNumber;
      this.administrativeManager = new AdministrativeManagerModel(object.administrativeManager);
      if (this.hasDpo) {
        this.firstNameDpo = object.firstNameDpo;
        this.lastNameDpo = object.lastNameDpo;
        this.emailDpo = object.emailDpo;
      }
    } else {
      this.administrativeManager = new AdministrativeManagerModel();
    }
  }
}
