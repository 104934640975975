import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {regex} from '../../regex/form.regex';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {catchError, Observable, Subject, Subscriber, Subscription} from 'rxjs';
import {BillingAddressService} from '../dropdowns/billing-address-dropdown/services/billing-address.service';
import {BillingAddressModel} from '../dropdowns/billing-address-dropdown/models/billing-address.model';
import {CountryService} from '../../service/country.service';
import {CountryModel} from '../../models/country.model';

@Component({
  selector: 'app-billing-address-form',
  templateUrl: './billing-address-form.component.html',
  styleUrls: ['./billing-address-form.component.scss']
})
export class BillingAddressFormComponent implements OnInit {
  private readonly companyId: string;
  billingAddressForm: FormGroup;
  loadingCountries = false;
  searchCountryInput$ = new Subject<string>();
  subscriptions: Subscription[] = [];
  countries: CountryModel[] = [];
  @Output() refreshTable = new EventEmitter();
  @Output() cancelForm = new EventEmitter();
  @Input() isLegalInfoUpdate: boolean = false;
  @Output() validationData: EventEmitter<{ isValid: boolean, data: BillingAddressModel }> = new EventEmitter<{
    isValid: boolean;
    data: BillingAddressModel
  }>();

  constructor(
    private _formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private service: BillingAddressService,
    private countryService: CountryService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    this.companyId = this.activatedRoute.parent.snapshot.paramMap.get('companyId');
    this.billingAddressForm = this._formBuilder.group({
      id: [''],
      companyId: this.companyId,
      name: ['', [Validators.required, Validators.pattern(regex.street)]],
      recipientName: [''],
      street1: ['', [Validators.required, Validators.maxLength(100)]],
      street2: ['', [Validators.maxLength(100)]],
      city: ['', [Validators.required, Validators.pattern(regex.city), Validators.maxLength(60)]],
      stateOrProvince: ['', [Validators.pattern(regex.stateOrProvince), Validators.maxLength(60)]],
      country: ['', [Validators.required, Validators.pattern(regex.country)]],
      postCode: ['', [Validators.pattern(regex.internationalPostalCode), Validators.minLength(3), Validators.maxLength(9), Validators.required]],
    });
  }

  ngOnInit(): void {
    this.subscribeSearchCountry();
    if (this.isLegalInfoUpdate) {
      this.subscriptions.push(this.billingAddressForm.valueChanges.subscribe(() => {
        this.validationData.emit({isValid: this.billingAddressForm.valid, data: this.billingAddressForm.value});
      }));
    }
  }

  cancelFormChanges() {
    this.billingAddressForm.reset();
    this.cancelForm.emit();
  }

  submitBillingAddressForm(): Observable<BillingAddressModel> {
    return new Observable(subscriber => {
      if (!this.billingAddressForm.get('id').getRawValue()) {
        this.createBillingAddress().subscribe(value => {
          subscriber.next(value);
          subscriber.complete();
        });
      } else {
        this.updateBillingAddress().subscribe(value => {
          subscriber.next(value);
          subscriber.complete();
        });
      }
    });
  }

  private afterCreateOrUpdate(address: BillingAddressModel, subscriber: Subscriber<BillingAddressModel>) {
    if (!this.isLegalInfoUpdate) {
      this.resetForm();
    } else {
      this.billingAddressForm.patchValue(address);
    }
    this.refreshTable.emit();
    if (!this.isLegalInfoUpdate) {
      this.toastr.success(this.translateService.instant('Legal_information.billing-address.update-billing-address.success'));
    }
    subscriber.next(address);
  }

  private updateBillingAddress(): Observable<BillingAddressModel> {
    return new Observable(subscriber => {
      this.service.updateBillingAddress(this.billingAddressForm.value).subscribe({
        next: (address: BillingAddressModel) => this.afterCreateOrUpdate(address, subscriber),
        error: (err) => this.handleErrors(err)
      });
    });
  }

  private createBillingAddress(): Observable<BillingAddressModel> {
    return new Observable(subscriber => {
      this.service.createBillingAddress(this.billingAddressForm.value).subscribe({
        next: (address: BillingAddressModel) => this.afterCreateOrUpdate(address, subscriber),
        error: (err) => this.handleErrors(err)
      });
    });
  }

  private subscribeSearchCountry() {
    this.subscriptions.push(
      this.searchCountryInput$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.loadingCountries = true),
        switchMap(searchTerm => {
            if (!searchTerm) {
              this.resetCountries();
              return [];
            }
            return this.countryService.search(searchTerm).pipe(
              map(data => data),
              catchError(() => {
                this.resetCountries();
                return [];
              })
            );
          }
        ),
        tap(() => this.loadingCountries = false)
      ).subscribe({
        next: countries => this.countries = countries,
        error: () => this.resetCountries()
      })
    );
  }

  private resetCountries() {
    this.countries = [];
    this.loadingCountries = false;
  }

  private handleErrors(err: { error: { status: number; title: string; }; }) {
    const { status, title } = err?.error || {};

    if (status === 409) {
      switch (title) {
        case 'ADDRESS_ALREADY_EXISTS':
          this.toastr.info(this.translateService.instant('Legal_information.billing-address.feedback.conflict-details'));
          break;
        default:
          this.toastr.error(this.translateService.instant('global.ts.error'));
          break;
      }
    } else {
      this.toastr.error(this.translateService.instant('global.ts.error'));
    }
  }

  resetForm() {
    this.billingAddressForm.reset();
  }
}
