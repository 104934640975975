import {Component, OnInit} from '@angular/core';
import {ListContentComponent} from '../../../../shared/components/list-content/list-content.component';
import {MenuItemModel} from '../../../../shared/models/menu-item.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ActivityReportService} from '../../../activity-report/services/activity-report.service';
import {NGXLogger} from 'ngx-logger';
import {ListContentModel} from '../../../../shared/models/list-content.model';
import {query, transition, trigger, useAnimation} from '@angular/animations';
import {slideIn50StaggerAnimation} from '../../../../shared/animation/common.animation';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {faPencil} from '@fortawesome/free-solid-svg-icons';
import {ButtonTypeEnum, IspColoursEnum, SizeEnum} from '@i-supplier/angular-shared-module';
import {faPenToSquare} from '@fortawesome/pro-duotone-svg-icons';

@Component({
  selector: 'activity-sheet-to-generate',
  templateUrl: './activity-sheet-to-generate.component.html',
  styleUrls: ['./activity-sheet-to-generate.component.scss'],
  animations: [
    trigger('slideInStagger', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ])
  ]
})
export class ActivitySheetToGenerateComponent extends ListContentComponent implements OnInit {
  protected readonly faPencil = faPencil;
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly faPenToSquare = faPenToSquare;
  protected readonly IspColoursEnum = IspColoursEnum;
  protected readonly SizeEnum = SizeEnum;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private activityReportService: ActivityReportService,
    private translateService: TranslateService,
    private toastrService: ToastrService,
    private logger: NGXLogger
  ) {
    super(router, route);
  }

  listParamValidator = {
    page: RegExp('^[1-9][0-9]*$'),
    size: ['5', '10', '20'],
    sort: RegExp('^(firstName|email|contact\.phones\.workPhoneNumber|accountingMonth)\,(asc|desc)$'),
    search: RegExp('.{3,}'),
  };
  menu: MenuItemModel[] = [];

  ngOnInit(): void {
    this.sort = 'accountingMonth';
    this.sortDirection = 'asc';
    super.ngOnInit();
    this.subscribeToQueryParam();
  }

  retrieveListContent(params: any): void {
    const page = params.page ? params.page - 1 : 0;
    const size = params.size ? params.size : 5;
    const sort = params.sort ? params.sort : 'accountingMonth,asc';
    const search = params.search ? params.search : null;
    this.activityReportService.getActivitySheetsWaitingForInvoiceGeneration(page, size, sort, search).subscribe({
      next: (res: ListContentModel) => {
      this.listContent = res.content;
      this.totalElements = res.totalElements;
      this.numberOfElements = res.numberOfElements;
      this.isListEmpty = !this.isSearchActive && res.empty;
      if (res.totalPages !== 0 && params.page > res.totalPages) {
        this.updateQueryParam({page: res.totalPages});
      }
      this.firstCallDone = true;
    },
      error: () => this.toastrService.error(this.translateService.instant('global.ts.error'))
    });
  }

  generateInvoice(activitySheet: any) {
    this.router.navigate(['/accounting/activity-sheet/', activitySheet.id, 'proforma-generation']);
  }
}
