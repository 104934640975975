<div class="modal-header modal-lg">
    <h4 class="modal-title pull-left">{{'app.cgu'|translate}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()" *ngIf="!cguToBeValidated">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="pdf-viewer">
      <pdf-viewer
        [src]="source"
        [show-all]="true"
        [original-size]="false"
        [(page)]="numPage"
        (after-load-complete)="afterLoadComplete($event)"
      ></pdf-viewer>
    </div>
  </div>
<div class="modal-footer">
  <div class="col-4"></div>
  <div class="col-4">
    <div class="d-flex justify-content-center" *ngIf="totalPages">
      <span>
        <isp-button *ngIf="numPage !== 1" [type]="ButtonTypeEnum.ICON" [icon]="faChevronCircleLeft" [tooltip]="'shared.components.pdf-preview.button.previous-page'|translate" [colour]="IspColoursEnum.success" (trigger)="decrement(); $event.stopPropagation();false"></isp-button>
        <span class="h4 mx-1">{{numPage}}/{{totalPages}}</span>
        <isp-button *ngIf="numPage !== totalPages" [type]="ButtonTypeEnum.ICON" [icon]="faChevronCircleRight" [tooltip]="'shared.components.pdf-preview.button.next-page'|translate" [colour]="IspColoursEnum.success" (trigger)="increment(); $event.stopPropagation();false"></isp-button>
      </span>
    </div>
  </div>
  <div class="col-4">
    <ng-container *ngIf="cguToBeValidated">
      <div class="float-right">
        <isp-button
          class="px-1"
          [type]="ButtonTypeEnum.CLASSIC"
          [colour]="IspColoursEnum.tertiary"
          [text]="'app.refuse'"
          (trigger)="refuseCgu()"
        ></isp-button>
        <isp-button
          class="px-1"
          [type]="ButtonTypeEnum.CLASSIC"
          [colour]="IspColoursEnum.primary"
          [text]="'app.accept'"
          (trigger)="acceptCgu()"
        ></isp-button>
      </div>
    </ng-container>
  </div>
</div>
